import React, { memo, type AbstractComponent } from 'react'
import styled, { css } from 'styled-components'
import { animateThrob } from './extras'
import { animated, useSpring } from '@react-spring/web'
import { DISPLAY_STATUS } from './extras'
import { ERROR_RED } from '../../../constants/styles'

type Props = {
	show: ?{ status: $Keys<typeof DISPLAY_STATUS> },
	setClickStatus: null => void,
}

const ScanProgressStyle: StyledType<> = styled(animated.rect)`
	x: -1;
	y: -1;
	fill: #00da33;
	height: 0;
	width: 100%;
	opacity: 0.6;
	${({ $status }) => {
		let color = $status === DISPLAY_STATUS.SUCCESS ? '#00DA33' : ERROR_RED
		return css`
			animation: ${animateThrob(color)} 2s infinite;
		`
	}};
	transform: rotate(-90deg);
`

function ScanProgress({ show, setClickStatus }: Props) {
	const springProps = useSpring({
		config: { duration: 2000 },
		immediate: show === null,
		height: show !== null ? 2 : 0,
		onRest: () => {
			if (show) {
				setClickStatus(null)
			}
		},
	})

	return (
		Boolean(show) && <ScanProgressStyle style={springProps} $status={show ? show.status : null} />
	)
}
export default (memo(ScanProgress): AbstractComponent<Props>)
