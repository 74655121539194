import React from 'react'
import styled from 'styled-components/macro'
import {
	BsArrowsAngleContract as ArrowsContract,
	BsArrowsAngleExpand as ArrowsExpand,
} from 'react-icons/bs'
import classnames from 'classnames'
import type { JuniorTeam } from '@mission.io/mission-toolkit'
import { rgba, APP_WHITE } from '../../../constants/styles'
import { ALPHABET_ARRAY } from '../../../constants'

// Display the Option letter on the top left of the canvas
export function OptionLetter({
	index,
	className,
}: {
	index: number,
	className?: string,
}): React$Node {
	const alphabetLetter = ALPHABET_ARRAY[index % ALPHABET_ARRAY.length]

	return (
		<div
			className={classnames(
				className,
				'absolute top-0 left-0 p-2 rounded-tl-[--border-radius] rounded-br-[--border-radius] text-white text-lg size-10 flex items-center justify-center'
			)}>
			{alphabetLetter}
		</div>
	)
}

// Display the team name on the bottom of the canvas
export function TeamName({
	team,
	isMyTeam,
	className,
}: {
	team: ?JuniorTeam,
	isMyTeam: boolean,
	className?: string,
}): React$Node {
	return team ? (
		<div
			className={classnames(
				className,
				'absolute bottom-0 p-2 rounded-bl-[--border-radius] rounded-tr-[--border-radius] text-white'
			)}>
			{isMyTeam ? `Your Team (${team.displayName})` : `${team.displayName} Team`}
		</div>
	) : null
}

/**
 * An icon that expands or contracts the canvas when clicked.
 * @param {MouseEvent => void} props.onClick a callback that is called when the icon is clicked
 * @param {string} props.className a class name to add to the icon wrapper div
 * @param {boolean} props.expanded whether or not the canvas is expanded
 */
export function ExpandIcon({
	onClick,
	className,
	expanded,
}: {
	onClick: (e: MouseEvent) => void,
	className?: string,
	expanded?: boolean,
}): React$Node {
	const iconProps = {
		color: 'white',
		style: { width: '80%', height: '80%' },
	}

	return (
		<div
			className={classnames(
				'hover:cursor-pointer z-[--layer-panels] rotate-90 absolute bottom-6 right-6 rounded-full bg-primary-700 min-w-6 aspect-square p-1 flex justify-center items-center',
				className
			)}
			onClick={e => {
				e.stopPropagation()
				onClick(e)
			}}>
			{expanded ? <ArrowsContract {...iconProps} /> : <ArrowsExpand {...iconProps} />}
		</div>
	)
}

/**
 * The wrapper for the display results step of the collaborative culminating moment screen. Uses `$cols`
 * and `$rows` to determine the number of columns and rows to display.
 * @param props
 * @param props.$cols The number of columns to display. This is the max number of entries in any row.
 * @param props.$rows How many rows of items there should be.
 */
const CanvasGridWrapper: StyledType<> = styled.div`
	${({ $cols, $rows }) => `
    --columns: ${$cols};
    --rows: ${$rows};
    --gap-size: ${$rows > 2 || $cols > 2 ? `var(--spacing2x)` : `var(--spacing4x)`};
`}
	overflow-y: auto;

	// computed values
	--column-gap-count: calc(var(--columns) - 1);
	--row-gap-count: calc(var(--rows) - 1);
	--child-width: calc((100% - (var(--column-gap-count) * var(--gap-size))) / var(--columns));
	--child-height: calc((100% - (var(--row-gap-count) * var(--gap-size))) / var(--rows));

	padding: 0 var(--gap-size);
	flex: 5;

	height: 100%;

	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: baseline;
	justify-content: flex-start;
	gap: var(--gap-size);

	> * {
		width: var(--child-width);
		max-height: var(--child-height);
	}

	&::-webkit-scrollbar {
		width: var(--spacing);
		height: var(--spacing);
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: ${rgba(APP_WHITE, 0.2)};

		&:hover {
			background: rgb(129, 122, 188, 0.75);
		}
	}
`

/**
 * Returns the column/row count for the given number of items.
 */
function getLayout(itemCount: number): { cols: number, rows: number } {
	// 1, 2, and 9 are special cases
	if (itemCount === 1) {
		return {
			cols: 1,
			rows: 1,
		}
	} else if (itemCount === 2) {
		return {
			cols: 2,
			rows: 1,
		}
	} else if (itemCount > 6 && itemCount <= 9) {
		return {
			cols: 3,
			rows: 3,
		}
	}

	// never have more than 4 columns or less than 2 rows
	return {
		cols: Math.min(4, Math.ceil(itemCount / 2)),
		rows: Math.max(2, Math.ceil(itemCount / 4)),
	}
}

/**
 * A layout to display a "grid" of canvases. Uses a flex box under the hood so that rows with fewer items can evenly space their children.
 * The number of columns and rows is calculated using `totalTeams`, and then the width and height of each child is calculated so
 * that all children are the same size on all rows.
 * @param props.className classes to add to the grid
 * @param props.children the children to display in the grid
 * @param props.totalTeams the number of teams to display, should be equivalent to the number of children
 * @param props.layout the number of columns and rows to use. If not provided, it will be calculated based on `totalTeams`.
 */
export function CanvasGrid(props: {
	className?: string,
	children: React$Node,
	totalTeams: number,
	layout?: { cols: number, rows: number },
}): React$Node {
	const layout = props.layout || getLayout(props.totalTeams)
	return (
		<CanvasGridWrapper $cols={layout.cols} $rows={layout.rows} className={props.className}>
			{props.children}
		</CanvasGridWrapper>
	)
}

export function CanvasGridItemWrapper(props: {
	onClick?: (e: MouseEvent) => void,
	className?: string,
	children: React$Node,
}): React$Node {
	const Element = props.onClick ? 'button' : 'div'

	return (
		<Element onClick={props.onClick} className={classnames(props.className)}>
			{props.children}
		</Element>
	)
}
