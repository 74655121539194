import React, { useEffect, useRef } from 'react'
import config from '../../config'
import { setTranslationLanguage } from '../../store/stores/settings'
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '../../constants/translationConstants'
import { useDispatch } from 'react-redux'
import { useGoogleTranslate } from '../../utility/hooks'
import styled from 'styled-components'
import { getLanguageCombo, isEnglishLanguage } from '../../utility/functions'

/**
 * A component that render the google translation combo box
 * @returns {React$Node}
 */
const GoogleTranslate = (): React$Node => {
	const loadGoogleTranslate = useGoogleTranslate()
	const googleRef = useRef()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!googleRef.current || !loadGoogleTranslate || !loadGoogleTranslate.loaded) {
			return
		}
		const googleElement = googleRef.current
		const checkLanguageCombo = () => {
			const languageCombo = getLanguageCombo()

			if (languageCombo) {
				const languageValue = languageCombo.selectedOptions[0].text
				if (languageValue) {
					dispatch(setTranslationLanguage(languageValue))
					localStorage.setItem('languageValue', languageValue)
				}
				if (isEnglishLanguage(languageValue)) {
					config.googleTranslateCookieDomains.forEach(domain => {
						eraseCookie(GOOGLE_TRANSLATE_COOKIE_NAME, domain)
					})
					window.location.reload()
				}
			}
		}
		googleElement.addEventListener('change', checkLanguageCombo)
		return () => {
			googleElement.removeEventListener('change', checkLanguageCombo)
		}
	}, [dispatch, loadGoogleTranslate])

	return (
		<GoogleTranslateWrapper>
			<div id="google_translate_element" ref={googleRef} />
		</GoogleTranslateWrapper>
	)
}

/**
 * Creates a cookie on the browser page.
 * Primary purpose of this function is to help erase an existing cookie on the browser.
 * @param {string} name name of cookie
 * @param {string} value value of cookie
 * @param {number} days time the cookie is valid for
 * @param {string} domain the web url domain that the cookie applies to.
 */
function createCookie(name, value, days, domain) {
	let expires
	if (days) {
		var date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = '; expires=' + date.toISOString()
	} else {
		expires = ''
	}
	document.cookie = name + '=' + value + expires + '; domain=' + domain + '; path=/'
}

/**
 * Erases a cookie from the browser with the given name and domain.
 * @param {string} name name of the cookie
 * @param {string} domain the web url domain which the cookie applies to.
 */
function eraseCookie(name, domain) {
	createCookie(name, '', -1, domain)
}

const GoogleTranslateWrapper = styled.div`
	span {
		white-space: break-spaces !important;
	}
`
export default GoogleTranslate
