import React from 'react'
import styled from 'styled-components'

const StyledName: StyledType<> = styled.div`
	width: fit-content;
	text-align: left;
	h4,
	h2 {
		margin: 0;
	}
`

const Name = ({
	value,
	style,
	className,
}: {
	value: string,
	style?: {},
	className?: string,
}): React$Node => {
	let name = value
	if (value.length > 14) {
		const words = value.split(' ')
		if (words.length > 1) {
			const lastInitial = words[words.length - 1][0]
			const firstWords = words.splice(0, words.length - 1)
			const firstName = firstWords.join(' ')
			name = `${firstName} ${lastInitial}.`
		}
	}
	return (
		<StyledName style={style} className={className}>
			<h2>{name}</h2>
		</StyledName>
	)
}

export default Name
