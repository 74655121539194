import { LoveFace, LikeFace, TolerateFace, HateFace } from '../../images/surveyIcons'

export const SURVEY_QUESTION_TYPES = {
	RATING: 'RATING',
}

export type SurveyQuestion = {
	id: string,
	phrase: string,
	type: 'RATING',
}

const MISSION_RATING_QUESTION = {
	id: 'missionRating',
	type: SURVEY_QUESTION_TYPES.RATING,
	phrase: 'How much did you like the mission?',
}

export const JUNIOR_SURVEY_QUESTIONS: SurveyQuestion[] = [MISSION_RATING_QUESTION]

export const JUNIOR_PLUS_SURVEY_QUESTIONS: SurveyQuestion[] = [MISSION_RATING_QUESTION]

export const RATING_OPTIONS = [
	{ text: "I really didn't like it", icon: HateFace },
	{ text: "I didn't like it", icon: TolerateFace },
	{ text: 'I liked it', icon: LikeFace },
	{ text: 'I loved it', icon: LoveFace },
]
