import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsJuniorPlus } from '../../store/stores/general'
import styled from 'styled-components'
import 'styled-components/macro'
import { sendMessage } from '../../store/stores/webSocket'
import { DARKEST_SHADE } from '../../constants/styles'
import {
	JUNIOR_SURVEY_QUESTIONS,
	JUNIOR_PLUS_SURVEY_QUESTIONS,
	type SurveyQuestion,
	SURVEY_QUESTION_TYPES,
	RATING_OPTIONS,
} from './constants'
import { SurveyBackground, QuestionDiv, Title, Phrase, Button } from './styledComponents'
import { LIGHT_PURPLE, ACCENT_GREEN, EOMS_GLOW_GREEN } from '../../constants/styles'

/**
 * A survey that is shown to the student after they complete a mission.
 * @returns {React$Node}
 */
export default function Survey(): React$Node {
	const isJrPlus = useSelector(getIsJuniorPlus)
	const questions = isJrPlus ? JUNIOR_PLUS_SURVEY_QUESTIONS : JUNIOR_SURVEY_QUESTIONS
	return <SurveyWithQuestions questions={questions} />
}

/**
 * Generic Survey that can be used for both mission types. Accepts one prop, "questions"
 * which can be customized in './constants.js' for each mission control set.
 * Each question is one page in the survey and the only question type supported right now is "rating"
 */
function SurveyWithQuestions({ questions }: { questions: SurveyQuestion[] }): React$Node {
	const dispatch = useDispatch()

	const [answerData, setAnswerData] = useState({
		missionRating: -1,
	})

	const [currentPage, setCurrentPage] = useState(0)
	const isLastQuestion = currentPage === questions.length - 1

	const question = questions[currentPage]
	const questionAnswer = answerData[question.id]

	const submit = () => {
		dispatch(sendMessage('STUDENT_SURVEY', { results: answerData }))
	}

	return (
		<SurveyBackground>
			<QuestionDiv>
				{questions.length > 1 ? <Title>FINISH SURVEY QUESTIONS TO CONTINUE </Title> : <Title />}
				<StyledPhrase>{question.phrase}</StyledPhrase>
				{question.type === SURVEY_QUESTION_TYPES.RATING ? (
					<Options>
						{RATING_OPTIONS.map(({ text, icon }, index) => {
							return (
								<BorderContainer
									key={String(question.id + '-' + index)}
									selected={questionAnswer === index}>
									<Option
										onClick={() =>
											setAnswerData(data => {
												const newData = { ...data }
												newData[question.id] = index
												return newData
											})
										}>
										<Image src={icon} />
										<QuestionText>{text}</QuestionText>
									</Option>
								</BorderContainer>
							)
						})}
					</Options>
				) : null}
				<ButtonDiv>
					<>
						<span css="width:10%;" />
						<PhaseCircleDiv>
							{questions.length > 1 &&
								questions.map((content, index) => {
									return <PhaseCircle glow={index <= currentPage} key={index} />
								})}
						</PhaseCircleDiv>
					</>
					<Button
						$shouldHide={questionAnswer === -1}
						onClick={() =>
							!isLastQuestion ? setCurrentPage(phase => (phase + 1) % questions.length) : submit()
						}>
						{isLastQuestion ? 'SUBMIT' : 'NEXT'}
					</Button>
				</ButtonDiv>
			</QuestionDiv>
		</SurveyBackground>
	)
}

const StyledPhrase = styled(Phrase)`
	padding: 16px;
`

const Options = styled.div`
	display: flex;
	align-items: center;
	height: 50%;
	width: 90%;
	color: white;
	font-size: 24px;
`

const BorderContainer = styled.div`
	flex: 1;
	width: 0;
	border-radius: 4px;
	padding: 16px;
	${({ selected }) => selected && `background: ${ACCENT_GREEN};`};
`

const Option = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	padding: 16px;
	border-radius: 4px;
	background: ${LIGHT_PURPLE};
	&:not(:last-child) {
		margin-right: 16px;
	}
`

const Image = styled.img`
	width: 100%;
	height: 100%;
	max-width: 131px;
	margin: 24px auto;
	height: auto;
`

const QuestionText = styled.div`
	width: calc(100% + 32px);
	margin: 16px 0 -16px 0;
	padding: 24px 8px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	font-family: proxima-nova, sans-serif;
	text-align: center;
	white-space: nowrap;
	font-size: 1.75vw;
	background: ${EOMS_GLOW_GREEN};
`

const ButtonDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 80%;
	margin-top: 24px;
`

const PhaseCircleDiv = styled.div`
	display: flex;
	justify-content: space-evenly;
	width: 30%;
`

const PhaseCircle = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${({ glow }) => (glow ? EOMS_GLOW_GREEN : DARKEST_SHADE)};
`
