import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'styled-components/macro'
import { getActiveStudentCount } from '../store/stores/general'

import HackerActions from './HackerActions'
import HackerData from './HackerData'

type Props = {
	totalStudents: number,
}

class HackerMode extends Component<Props> {
	render() {
		return (
			<main css="background-color: rgb(40, 56, 108);">
				<div>Total Connected Students: {this.props.totalStudents}</div>
				<div className="row">
					<HackerActions />
					<HackerData />
				</div>
			</main>
		)
	}
}

function mapStateToProps(state) {
	return {
		totalStudents: getActiveStudentCount(state),
	}
}

export default (connect(mapStateToProps)(HackerMode): (props: $Shape<{||}>) => React$Node)
