import React from 'react'
import { TRAINING } from '@mission.io/mission-toolkit/constants'
import TextToSpeech from '../../TextToSpeech'

/**
 * The prompt for the current issue.
 */
export function Prompt({ content }: { content: ?string }): React$Node {
	return (
		<div
			data-training-target={TRAINING.IDS.CREATIVE_CANVAS_PROMPT_EXPLAINED}
			className="w-full  bg-primary-600 p-4 rounded-2xl">
			<h3 className="m-0 text-base md:text-lg lg:text-xl xl:text-2xl">
				<TextToSpeech>{content || 'Prompt Unavailable'}</TextToSpeech>
			</h3>
		</div>
	)
}
