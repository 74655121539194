import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { enablePatches } from 'immer'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import config from './config'
import App from './App'
import { hackToHandleGoogleTranslateError } from './utility/googleTranslateHack'

enablePatches()

if (config.isProd) {
	hackToHandleGoogleTranslateError()

	if (config.logRocketUrl) {
		LogRocket.init(config.logRocketUrl)
		setupLogRocketReact(LogRocket)
	}

	if (config.sentryDsn) {
		Sentry.init({
			dsn: config.sentryDsn,
			ignoreErrors: [
				// we are ignoring this because its chrome throwing an error for slow animations
				// and because everyone else on stack overflow agrees that its ignorable
				'ResizeObserver loop limit exceeded',
				// Errors that come from Google Translate. I could not figure out how to catch the errors, and we don't want them taking up our Sentry quota.
				/Cannot read properties of null \(reading '__closure_events_fn_/,
				/Cannot read property '__closure_events_fn_/,
			],
			// Don't send the message if the error stack trace has translate_http in it
			beforeSend(event, hint) {
				const error = hint.originalException
				if (error?.stack?.includes('translate_http')) {
					return null
				}
				return event
			},
		})
		if (config.logRocketUrl) {
			LogRocket.getSessionURL(sessionURL => {
				Sentry.configureScope(scope => {
					scope.setExtra('LogRocket sessionURL', sessionURL)
				})
			})
		}
	}
}

const container = document.getElementById('root')
// $FlowFixMe
createRoot(container).render(<App />)
