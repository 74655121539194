import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Main from './Main'
import { IconContext } from 'react-icons'
import { Provider } from 'react-redux'
import Login from './components/LoginPage'
import * as Sentry from '@sentry/react'
import store from './store'
import AppSetup, {
	getNewMissionIOUrlFromInfinidIOUrl,
	isConnectedToMission,
	isWantingToRunMissionAsControl,
	isWantingToRunMissionAsStudent,
} from './components/AppSetup'
import { WebsiteUrlChange } from './components/WebsiteUrlChange'
import { getLoginError } from './store/selectors/sharedSelectors'
import { ONE_SECOND } from './constants'
import useInterval from 'use-interval'
import config from './config'
import RalfErrorIllustration from './images/RALFErrorIllustration.png'
import './index.css'

const USE_LOGIN = true

function App(): React$Node {
	const connected = isConnectedToMission()
	const isConnectedAsStudent = isWantingToRunMissionAsStudent()
	const isConnectedAsController = isWantingToRunMissionAsControl()
	const loginError = useSelector(getLoginError)
	const newMissionIoUrl = getNewMissionIOUrlFromInfinidIOUrl(window.location.href)

	return (
		<div className="App" id="App-root">
			{newMissionIoUrl ? (
				<WebsiteUrlChange newUrl={newMissionIoUrl} />
			) : loginError || (!connected && USE_LOGIN) ? (
				<Login />
			) : (
				<AppSetup>
					{isConnectedAsStudent ? (
						<Main />
					) : isConnectedAsController ? (
						<Main isController />
					) : (
						<div>Could not determine connection type</div>
					)}
				</AppSetup>
			)}
		</div>
	)
}

const RELOAD_TIME_SECONDS = 8

/**
 * This is a fallback component for when the app crashes. Automatically refreshes the page after RELOAD_TIME_SECONDS seconds
 */
function FallbackComponent() {
	const [timeTilReload, setTimeTilReload] = useState(RELOAD_TIME_SECONDS)

	useInterval(() => {
		if (timeTilReload <= 0) {
			window.location.reload()
			return
		}
		setTimeTilReload(t => t - 1)
	}, ONE_SECOND)

	return (
		<div
			css={`
				background-image: linear-gradient(90deg, #0d3a58 0%, #3d357d 99%);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				color: white;
				width: 60%;
				margin: auto;
				height: 100vh;
				width: 100vw;
			`}>
			<img
				src={RalfErrorIllustration}
				css="height: 20em; filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.75));"
				alt="Confused RALF"
			/>
			<h1>RALF’s batteries ran out!</h1>
			You will be reconnected in {timeTilReload}...
		</div>
	)
}

/**
 * This is the top level component for the app. It wraps App in ErrorBoundaries and Providers
 */
export default function AppWithProviders(): React$Node {
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<IconContext.Provider value={{ className: 'infinid-react-icons' }}>
					<App />
				</IconContext.Provider>
			</Provider>
		</ErrorBoundary>
	)
}

/**
 * Wraps its children in an ErrorBoundaries component
 * Does not wrap children in the the ErrorBoundary if the dev flag `hideSentryErrorFallbackPage` is true
 */
function ErrorBoundary({ children }) {
	if (config.devFlags?.hideSentryErrorFallbackPage) return children
	return (
		<Sentry.ErrorBoundary
			// FallbackComponent must be inside this anonymous function because it uses hooks
			fallback={() => <FallbackComponent />}
			showDialog>
			{children}
		</Sentry.ErrorBoundary>
	)
}
