import React from 'react'
import styled from 'styled-components/macro'

/**
 * A decorative svg design that appears on the left side of the extender frame when in the horizontal view for a literacy event.
 * @returns {React$Node}
 */
export function DecalExtenderFrame(): React$Node {
	return (
		<FillerDiv>
			<AbsoluteDiv>
				<svg viewBox="0 0 10 10">
					<path d="M0 0 L10 10 L0 10 L0 0 Z" fill="#25346C" />
					<path
						d="M10 13 150 13 L170 33"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
				</svg>
				<div css="flex-grow: 1;width: 10px; background-color: #25346c;"></div>
				<svg viewBox="0 0 10 10">
					<path d="M0 0 L10 0 L0 10 L0 0 Z" fill="#25346C" />
					<path
						d="M10 -3 L70 -3 L90 -23"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
					<circle
						cx="98"
						cy="-8"
						r="5"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
					<circle
						cx="116"
						cy="-8"
						r="5"
						className="stroke-accent-aqua"
						strokeWidth="3"
						fill="transparent"
					/>
				</svg>
			</AbsoluteDiv>
		</FillerDiv>
	)
}

const FillerDiv = styled.div`
	align-self: stretch;
	position: relative;
	width: 10px;
	svg {
		width: 10px;
		overflow: visible;
	}
`

const AbsoluteDiv = styled.div`
	position: absolute;
	top: -32px;
	display: flex;
	flex-direction: column;
	height: calc(100% + 64px);
`
