import React, { useState } from 'react'
import { ReactModal } from '../../../basics'
import { IconButton } from '../../../basics/Buttons'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import styled, { keyframes } from 'styled-components/macro'
import classnames from 'classnames'
import { TeamLayout } from '../../TeamLayout'
import { useTeam } from '../../../../store/selectors/jrPlusState/teams'
import type { MediaModalOverrides } from '../../../ContextualDataCard'

const ANIMATION_TIME_MS = 400

const CANVAS_MODAL_Z_INDEX = 1003
const SHOW_MEDIA_MODAL_ABOVE_THIS_MODAL: MediaModalOverrides = {
	frameContent: false, // position modal across entire screen
	overlayStyle: { zIndex: CANVAS_MODAL_Z_INDEX + 1 },
}

/**
 * A full screen view that displays the canvas of the selected team
 * @param {} param0
 * @returns
 */
export function CreativeCanvasFullScreen({
	teamId,
	documentId,
	onClose: onCloseOverride,
	isOpen: isOpenOverride,
	canvasId,
}: {
	teamId: string,
	documentId: string,
	canvasId: string,
	onClose?: (SyntheticMouseEvent<HTMLButtonElement>) => void,
	isOpen?: boolean,
}): React$Node {
	const team = useTeam(teamId)
	const [closing, setClosing] = useState(false)
	const [defaultOpenState, setDefaultOpenState] = useState(false)
	const onClose = onCloseOverride || (e => setDefaultOpenState(false))
	const handleOpenState = e => {
		if (isOpenOverride || defaultOpenState) {
			setClosing(true)
			setTimeout(() => onClose(e), ANIMATION_TIME_MS)
		} else {
			setClosing(false)
			setDefaultOpenState(true)
		}
	}
	const isOpen = isOpenOverride || defaultOpenState
	if (!team) {
		throw Error('Team does not exist! This should be impossible')
	}
	const Content = (
		<div className="flex flex-col size-full">
			<div className="flex p-2 bg-white/20">
				<IconButton
					aria-label="go back"
					onClick={handleOpenState}
					className="h-full top-0 left-8 my-auto hover:!scale-125">
					{isOpen ? <FaAngleDown size={30} /> : <FaAngleUp size={30} />}
				</IconButton>
				<h1 className="text-center m-0 text-xl md:text-4xl flex-1">{team.displayName} team</h1>
			</div>
			<div className="flex-1 py-2">
				<TeamLayout
					canvasId={canvasId}
					teamId={team.id}
					documentId={documentId}
					isTeacher
					mediaModalOverrides={SHOW_MEDIA_MODAL_ABOVE_THIS_MODAL}
				/>
			</div>
		</div>
	)

	return (
		<>
			{!isOpen && Content}
			<ReactModalWithAnimation
				isOpen={isOpen}
				className={classnames(
					'w-screen h-screen p-0 m-0 top-0 left-0 transform-none text-white bg-primary-800',
					closing && 'closing-animation'
				)}
				overlayClassName="fixed top-0 left-0 z-40 h-screen w-screen p-0">
				{Content}
			</ReactModalWithAnimation>
		</>
	)
}

const slideIn = keyframes`
/* ----------------------------------------------
 * Generated by Animista on 2023-10-18 14:40:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

0%{
    transform:translateZ(-1400px) translateY(800px);
    opacity:0
}
100%{
    transform:translateZ(0) translateY(0);
    opacity:1
}
`

const slideOut = keyframes`

/* ----------------------------------------------
 * Generated by Animista on 2023-10-18 20:44:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

0%{
    transform: translateZ(0) translateY(0);
    opacity:1
}
100%{
    transform: translateZ(-1400px) translateY(800px);
    opacity:.4
}
`

const ReactModalWithAnimation = styled(ReactModal)`
	animation: ${slideIn} ${ANIMATION_TIME_MS}ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	&.closing-animation {
		animation: ${slideOut} ${ANIMATION_TIME_MS}ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	}
`
