import type { CreativeCanvasStationCriterion } from '@mission.io/mission-toolkit'
import type { CreativeCanvasDataWithoutMap } from '@mission.io/mission-toolkit/actions'
import {
	COLLABORATIVE_CULMINATING_MOMENT,
	CREATIVE_CANVAS_STATION,
} from '@mission.io/mission-toolkit/constants'
import GenericIcon from '../../images/mapIcons/generic.svg'
export const TRAINING_CANVAS_PROPS = {
	canvasId: 'TRAINING_CANVAS',
	teamId: 'TRAINING_TEAM',
	documentId: 'TRAINING_DOCUMENT',
	currentStepStatus: COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.EDITING,
}
export const TRAINING_CANVAS_DATA: {
	data: Array<CreativeCanvasDataWithoutMap<string>>,
	criteria: Array<CreativeCanvasStationCriterion>,
	prompt: string,
} = {
	data: [
		{
			type: 'TEXT',
			text: 'Some useful data about the planet',
			relevance: 3,
			icon: GenericIcon,
			name: 'Planet X',
			_id: 'training-data-1',
		},
		{
			type: 'TEXT',
			text: 'Some interesting data about the people living on Planet X',
			relevance: 1,
			icon: GenericIcon,
			name: 'Planet X Population',
			_id: 'training-data-2',
		},
	],
	prompt: 'Design a new device that will save the planet',
	criteria: [
		{
			id: 'training-criterion-1',
			text: 'Does it use renewable energy?',
			displayType: CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.VISIBLE,
			gradingOptions: [
				{
					id: 'grading-option-1',
					text: 'Yes',
					score: 1,
					correct: true,
				},
				{
					id: 'grading-option-2',
					text: 'No',
					score: 0,
					correct: false,
				},
			],
			required: true,
		},
		{
			id: 'training-criterion-2',
			text: 'Design includes a list of parts needed.',
			displayType: CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.VISIBLE,
			gradingOptions: [
				{
					id: 'grading-option-1',
					text: 'Yes',
					score: 1,
					correct: true,
				},
				{
					id: 'grading-option-2',
					text: 'No',
					score: 0,
					correct: false,
				},
			],
			required: true,
		},
		{
			id: 'training-criterion-3',
			text: 'How many people on the planet will be saved?',
			displayType: CREATIVE_CANVAS_STATION.RUBRIC.CRITERIA.DISPLAY_TYPE.VISIBLE,
			gradingOptions: [
				{
					id: 'grading-option-1',
					text: 'Only a few people',
					score: 0,
					correct: false,
				},
				{
					id: 'grading-option-2',
					text: 'Half the planet',
					score: 0.5,
					correct: false,
				},
				{
					id: 'grading-option-2',
					text: 'The whole planet!',
					score: 1,
					correct: true,
				},
			],
			required: true,
		},
	],
}
