// @flow
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getBaseWebSocketUrl } from '../../../store/stores/webSocket'
import { WebsocketProvider } from 'y-websocket'
import { isController } from '../../AppSetup'
import { type YMap, Doc } from 'yjs'
import { getCanvasId } from '../connect/selectors'
import { usePrevious } from '../../../utility/hooks'

export type DocumentData = {
	id: string,
	doc: Doc,
	shapes: YMap<string, { id: string }>,
}

export type Identity = {|
	missionCode: string,
	// If studentId is not set, attempt to connect as a teacher
	studentId?: ?string,
	roomId: string,
|}

type WebSocketDocumentMap = {
	[documentId: string]: { ws: WebsocketProvider, status: string, synced: boolean },
}

type Context = {
	getDoc: Identity => DocumentData,
	webSockets: WebSocketDocumentMap,
	setWebSockets: ((WebSocketDocumentMap) => WebSocketDocumentMap) => void,
}

const CreativeCanvasDocumentContext = React.createContext<Context | void>()

/**
 * Handles websocket connection for showing multiple canvases in the player at the same time.
 */
export function CreativeCanvasDocumentProvider({ children }: { children: React$Node }): React$Node {
	const _docs = React.useRef<{ [documentId: string]: DocumentData }>({})
	const [webSockets, setWebSockets] = React.useState<{
		[documentId: string]: { ws: WebsocketProvider, status: string, synced: boolean },
	}>({})

	/**
	 * Gets doc data for a specific document id. Only will return an
	 * un-truthy value if the CanvasConnectionProvider is not initialized.
	 * @param {string}
	 */
	const getDoc = React.useCallback((identity: Identity) => {
		const { roomId: id } = identity
		if (_docs.current && _docs.current[id]) {
			return _docs.current[id]
		}
		const doc = new Doc()
		const shapes = doc.getMap('shapes')
		_docs.current[id] = {
			id,
			doc,
			shapes,
		}
		return _docs.current[id]
	}, [])

	return (
		<CreativeCanvasDocumentContext.Provider value={{ getDoc, webSockets, setWebSockets }}>
			{children}
		</CreativeCanvasDocumentContext.Provider>
	)
}

/**
 * This hook is used to connect to access the yjs web socket for a given document id..
 * @param {Identity} identity identifiers to help access the correct y document for a given canvas; missionCode, studentId, and roomId
 */
export function useOneYjsWebsocket(
	identity: Identity
): { ws: WebsocketProvider, status: string, synced: boolean } {
	const context = useContext(CreativeCanvasDocumentContext)
	if (!context) {
		throw new Error('Cannot access yjs websocket without CreativeCanvasDocumentProvider')
	}
	const { webSockets, setWebSockets, getDoc } = context
	const websocketUrl = useSelector(getBaseWebSocketUrl)
	const isTeacher = isController()
	const loading = React.useRef({})

	const shouldCleanUp = useIsCreativeCanvasOver()

	React.useEffect(() => {
		if (shouldCleanUp) {
			Object.keys(webSockets).forEach(websocketId => {
				webSockets[websocketId]?.ws.destroy()
			})
			setWebSockets(() => ({}))
			loading.current = {}
			return
		}
		if (webSockets[identity.roomId] || loading.current[identity.roomId]) {
			return
		}
		const { doc } = getDoc(identity)
		if (!websocketUrl) {
			throw new Error('Cannot connect websocket without websocket url')
		}
		loading.current = {
			...loading.current,
			[identity.roomId]: true,
		}
		const queryParams: {| missionCode: string, studentId?: string, isTeacher?: string |} = {
			missionCode: identity.missionCode,
		}
		if (isTeacher) {
			queryParams.isTeacher = 'true'
		} else if (identity.studentId) {
			queryParams.studentId = identity.studentId
		}

		const ws = new WebsocketProvider(
			websocketUrl + '-document-collaboration',
			identity.roomId,
			doc,
			{
				params: queryParams,
				connect: true,
			}
		)
		setWebSockets(prev => ({
			...prev,
			[identity.roomId]: { ws, status: 'loading', synced: false },
		}))

		ws.on('status', ({ status }: { status: 'connecting' | 'disconnected' | 'connected' }) => {
			setWebSockets(prev => {
				delete loading.current[identity.roomId]
				if (!prev[identity.roomId]) return prev
				return {
					...prev,
					[identity.roomId]: { ...prev[identity.roomId], status },
				}
			})
		})

		ws.on('sync', (isSynced: boolean) => {
			setWebSockets(prev => {
				delete loading.current[identity.roomId]
				if (!prev[identity.roomId]) return prev
				return {
					...prev,
					[identity.roomId]: { ...prev[identity.roomId], synced: isSynced },
				}
			})
		})
	}, [identity, webSockets, getDoc, setWebSockets, websocketUrl, isTeacher, shouldCleanUp])

	return webSockets[identity.roomId]
}

/**
 * Gets the DocumentData for a specific document id. If the document does not exist, creates one
 * and returns it.
 *
 * @param {string} documentId
 * @returns {DocumentData}
 */
export function useDocument(identity: Identity): DocumentData {
	const context = useContext(CreativeCanvasDocumentContext)
	if (!context) {
		throw new Error('Cannot access document data without CreativeCanvasDocumentProvider')
	}
	return context?.getDoc(identity)
}

/**
 * A hook that returns true if there was a creative canvas but now there is not.
 * @returns {boolean}
 */
function useIsCreativeCanvasOver() {
	const canvasId = useSelector(getCanvasId)
	const previousCanvasId = usePrevious(canvasId)

	if (!canvasId && previousCanvasId) {
		return true
	}
}
