import {
	getMissionType,
	getStudentId,
	JUNIOR,
	getStudentName,
	getCollaborativeCulminatingMomentStep,
} from '../stores/general'
import {
	getCulminatingMomentQuestionsFromAnalytics,
	getIsQuestionSlotJrPlus,
	getStudentCulminatingMomentAnswersFromAnalytics,
	getStudentQuestionAnswersFromAnalytics,
	getStudentQuestionData as getJrPlusStudentQuestionData,
} from './jrPlusState/questionData'
import { reduceObject, mapObject, values } from '../../utility/functions'
import { getAllQuestions, getQuestionActions } from '../stores/staticData'
import {
	getCulminatingMomentResultFromMission,
	getCulminatingMomentDataFromMission,
	getScreenFromMission,
	getCanEditScreenTimerFromMission,
	getPausedStateKeyFromMission,
	getTimersFromMission,
	getIsPausedFromMission,
	getAllPointsForStudent,
	getClassThrusterDirection,
	getStudents as getStudentsFromMission,
	getIsMissionPauseActiveFromMission,
	getDeathStatusFromMission,
	areSurveysCompleted,
} from '@mission.io/mission-toolkit'
import {
	NO_PROGRESS_TIMER,
	MISSION_TYPES,
	THRUSTERS_STATION,
	COLLABORATIVE_CULMINATING_MOMENT,
} from '@mission.io/mission-toolkit/constants'

import type {
	StudentWithPoints,
	JuniorQuestionData,
	JuniorMissionFullState,
	JuniorPlusMissionFullState,
	BothFullMissionStates,
	JuniorPointsData,
	JuniorSharedStudent,
	Screen,
	QuestionInfo,
	DeltaClockData,
	DeltaClockFunction,
	Direction,
	PausedStateKey,
	Timer,
	JuniorPlusQuestionData,
	MissionPause,
	CrewDeathStatus,
	CreativeCanvasStationIssue,
	CollaborativeCulminatingMomentAnalyticsScore,
} from '@mission.io/mission-toolkit'
import type { ReduxStore } from '../rootReducer'
import type { AnalyticsStore } from '../stores/analytics'

import {
	type ClientQuestion as Question,
	variantTypes,
	type Score,
	type StudentAnswer,
} from '@mission.io/question-toolkit'
import type {
	NormalizedQuestionResponse,
	QuestionAnswerStore,
	QuestionAndResponses,
} from '../../types'
import type { LoginError } from '../stores/general'
import { getTraining } from './jrPlusState/training'
import { convertToMarkdownString } from '@mission.io/question-views'

export { NO_PROGRESS_TIMER }

type SharedStationInfo =
	| $PropertyType<JuniorMissionFullState, 'stationData'>
	| $PropertyType<JuniorPlusMissionFullState, 'stationData'>

export function getFullState(store: ReduxStore): BothFullMissionStates {
	return getMissionType(store) === JUNIOR ? store.jrState : store.jrPlusState
}

/**
 * Returns the current screen running on the mission
 * @param {ReduxStore} state redux store
 * @returns {?Screen} the id of a screen
 */
export function getCurrentScreen(state: ReduxStore): ?Screen {
	return getScreenFromMission(getFullState(state))
}

type EditScreenTimer = { add: boolean, subtract: boolean }
/**
 * Returns whether the current screen timer can be edited or not.
 * @param {ReduxStore} state redux store
 * @returns { EditScreenTimer } if the screen can be edited
 */
export function getCanEditScreenTimer(state: ReduxStore): EditScreenTimer {
	const screen = getCurrentScreen(state)
	const enable = getCanEditScreenTimerFromMission(getFullState(state))

	if (!screen) {
		return { add: false, subtract: false }
	}
	if (screen.type === 'VIDEO' && screen.finalFrameDuration && screen.finalFrameDuration > 0) {
		return { add: true, subtract: enable }
	}
	return { add: enable, subtract: enable }
}

function getStationsState(store: ReduxStore): SharedStationInfo {
	return getMissionType(store) === JUNIOR
		? store.jrState.stationData
		: store.jrPlusState.stationData
}

/**
 * Returns the death status
 * @param {State} state redux store
 * @returns {CrewDeathStatus} death status
 */
export function getDeathState(state: ReduxStore): ?CrewDeathStatus {
	return getDeathStatusFromMission(getFullState(state))
}

/**
 * Returns the mission pause info from the actual pause state
 * @param {State} state redux store
 * @returns {MissionPause} the mission pause info
 */
export function getMissionPauseData(state: ReduxStore): ?MissionPause {
	return getFullState(state)?.pausedState.missionPause
}

/**
 * Returns the pause state keys
 * @param {State} state redux store
 * @returns {PausedStateKey} the pause state key
 */
export function getPausedStateKey(state: ReduxStore): ?PausedStateKey {
	return getPausedStateKeyFromMission(getFullState(state))
}

/**
 * Returns whether the mission is paused or not.
 * @param {ReduxStore} state redux store
 * @returns {boolean} the pause state
 */
export function isMissionPaused(state: ReduxStore): boolean {
	return getIsPausedFromMission(getFullState(state))
}

/**
 * Returns whether the mission pause is active or not.
 * @param {ReduxStore} state redux store
 * @returns {boolean} the pause state
 */
export function isMissionPauseActive(state: ReduxStore): boolean {
	return getIsMissionPauseActiveFromMission(getFullState(state))
}

/**
 * Returns whether the mission pause is active or the mission is paused.
 * @param {ReduxStore} state redux store
 * @returns {boolean} the pause state
 */
export function isAnyPauseInMission(state: ReduxStore): boolean {
	return (
		getIsPausedFromMission(getFullState(state)) ||
		getIsMissionPauseActiveFromMission(getFullState(state))
	)
}

/**
 * Gets the current objective timerId from the state.
 * @param {ReduxStore} state redux store
 * @returns {?string} the current objective timer id
 */
export function getCurrentObjectiveTimerId(state: ReduxStore): ?string {
	const missionState = getFullState(state)
	if (!missionState) {
		return null
	}
	const screen = missionState.launchpad.screen
	return screen ? screen.timerId : null
}

export const getTimers = (
	timerIds?: ?Array<?string>
): ((state: ReduxStore) => { [timerId: string]: Timer }) => (
	state: ReduxStore
): { [string]: Timer } => {
	return getTimersFromMission(timerIds)(getFullState(state))
}

export function getDeltaState(store: ReduxStore): DeltaClockData {
	return getMissionType(store) === JUNIOR ? store.jrState.deltaClock : store.jrPlusState.deltaClock
}

export function getThrusters(
	store: ReduxStore
): $PropertyType<$PropertyType<JuniorPlusMissionFullState, 'stationData'>, 'thrusters'> {
	return getStationsState(store).thrusters
}

export function getTransporter(
	store: ReduxStore
):
	| $PropertyType<$PropertyType<JuniorPlusMissionFullState, 'stationData'>, 'transporter'>
	| $PropertyType<$PropertyType<JuniorMissionFullState, 'stationData'>, 'transporter'> {
	return getStationsState(store).transporter
}

// POINTS

export function getPoints(store: ReduxStore): JuniorPointsData {
	return getFullState(store).points
}

/**
 * Function that get the points average
 * for all students connected
 * @param {*} store
 * @returns
 */
export function getPointsAverage(store: ReduxStore): number {
	const studentsObj = getFullState(store).students
	let totalPoints = 0
	let totalStudentsConnected = 0
	for (let studentId in studentsObj) {
		if (studentsObj[studentId].connected) {
			totalStudentsConnected++
			totalPoints = totalPoints + getAllPointsForStudent(getFullState(store), studentId)
		}
	}

	return Math.round(totalPoints / totalStudentsConnected)
}

export function getCurrentStudentPoints(store: ReduxStore): number {
	const id = getStudentId(store.general)
	if (!id) {
		return 0
	}
	return getAllPointsForStudent(getFullState(store), id)
}

export function getStudents(store: ReduxStore): IdMap<JuniorSharedStudent> {
	// $FlowFixMe[prop-missing]
	// $FlowFixMe[incompatible-return] mission-toolkit we need a better shared student type in mission-toolkit
	return getFullState(store).students
}

export function getShipHealth(store: ReduxStore): ?number {
	return getFullState(store).shipHealth
}

export function getCulminatingMomentVote(store: ReduxStore): ?string {
	const screen = getScreen(store)
	if (screen?.type !== 'CULMINATING_MOMENT') {
		return
	}
	const culminatingMomentId = String(screen?._actionId)
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return
	}
	const state = getFullState(store)
	const studentResponses = state.culminatingMomentData[culminatingMomentId]?.responses[studentId]
	if (!studentResponses || studentResponses.length === 0) {
		return
	}
	return studentResponses[studentResponses.length - 1]
}

// THRUSTERS

export function isThrustersActive(store: ReduxStore): boolean {
	return getThrusters(store).isActive
}

export function isRepairsActive(store: ReduxStore): boolean {
	if (getTraining(store)?.repairsPhase.status === 'ACTIVE') {
		return true
	}
	const repairStation =
		getMissionType(store) === JUNIOR
			? store.jrState.stationData.repair
			: store.jrPlusState.stationData.decks
	return repairStation.active
}

/**
 * getThrusterClassDirection - get the direction that the class's thrusters are going
 *
 * @param {ReduxStore} store  - the redux store to get the student's thrusters direction from
 *
 * @return {Direction} - the direction the class's thrusters are going
 */
export function getThrusterClassDirection(store: ReduxStore): Direction {
	return getClassThrusterDirection(getFullState(store))
}

/**
 * getMyServerThrustersDirection - get the thrusters direction for the student on the current mission's fullState
 *
 * @param {ReduxStore} store - the redux store to get the student's thrusters direction from
 *
 * @return {?Direction} - the direction of the student's thruster (null/undefined if student does not have a thruster direction or student could not be found)
 */
export function getMyServerThrustersDirection(store: ReduxStore): ?Direction {
	const thrusters = getThrusters(store)
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return null
	}
	return thrusters.studentData[studentId]?.direction
}

/**
 * Gets the upgrade level of the current student for the thrusters station
 */
export function getEngineIndex(store: ReduxStore): number {
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return 0
	}
	return (
		getThrusters(store).studentData[studentId]?.engine.level ?? THRUSTERS_STATION.DEFAULT_UPGRADE
	)
}

// TRANSPORTER

export function getGroupSegmentsTransported(store: ReduxStore): number {
	return reduceObject(getTransporter(store).studentData, (sum, student) => sum + student.total, 0)
}

export function isTransporterActive(store: ReduxStore): boolean {
	const transporter = getTransporter(store)
	return transporter.groupRequiredSegmentCount !== 0 && transporter.status === 'ONLINE'
}

// Remote Missions

export function getIsCurrentStudentMuted(store: ReduxStore): boolean {
	const id = getStudentId(store.general)
	if (!id) {
		return true
	}
	const student = getStudent(store, id)
	if (!student) {
		return true
	}
	return student.isMuted
}

export function getIsCurrentStudentHandRaised(store: ReduxStore): boolean {
	const id = getStudentId(store.general)
	if (!id) {
		return false
	}
	const student = getStudent(store, id)
	if (!student) {
		return false
	}
	return student.isHandRaised
}

// DELTA CLOCK

export function getTimer(store: ReduxStore, timerId: ?string): ?Timer {
	let state = getDeltaState(store)
	if (!state || !timerId) return null

	let timer = state.pausedTimers[timerId]
	if (timer) {
		return {
			isPaused: true,
			originalDelay: timer.originalDelay,
			delayLeft: timer.delay,
			currentProgress: timer.previousProgress || 0,
			id: timerId,
			tag: null,
		}
	}
	let delay = 0
	let serializedDeltaFunction: ?DeltaClockFunction = null
	for (let deltaFunction: DeltaClockFunction of state.runningTimers) {
		delay += deltaFunction.delay
		if (deltaFunction.id === timerId) {
			serializedDeltaFunction = deltaFunction
			break
		}
	}
	if (!serializedDeltaFunction) {
		return null
	}
	let delayLeft = Math.max(delay, 0)
	if (state.lastRunTime) {
		const lastRunTime: number = state.lastRunTime
		delayLeft = Math.max(delayLeft - (Date.now() - lastRunTime), 0)
	}
	const originalDelay = serializedDeltaFunction.originalDelay
	const previousProgress = serializedDeltaFunction.previousProgress || 0
	const currentProgress = 1 - (delayLeft / originalDelay) * (1 - previousProgress)
	return {
		isPaused: false,
		originalDelay,
		delayLeft,
		currentProgress,
		id: timerId,
		tag: null,
	}
}

// STUDENTS
export function getStudentsWithPoints(
	store: ReduxStore,
	includeOwners: boolean
): ?{ [studentId: string]: StudentWithPoints } {
	return getStudentsFromMission(getFullState(store), includeOwners)
}
export function getStudentPoints(store: ReduxStore, studentId: string): number {
	return getAllPointsForStudent(getFullState(store), studentId)
}

export function getStudent(store: ReduxStore, studentId: string): ?JuniorSharedStudent {
	return getStudents(store)[studentId]
}

export function getActiveStudents(store: ReduxStore): JuniorSharedStudent[] {
	return reduceObject(
		getStudents(store),
		(activeStudents, student) => {
			if (student.connected) {
				activeStudents.push(student)
			}
			return activeStudents
		},
		[]
	)
}

export function getQuestionData(store: ReduxStore): JuniorPlusQuestionData | JuniorQuestionData {
	return getFullState(store).questionData
}

/**
 * This function takes a student's ID and returns an object array of all the other students excluding the student who made the request.
 * It gets all students, filters out the studentId that was passed in, and forEach's to return an array of the other student's data
 *
 * @param { ReduxStore } state our beloved ReduxStore
 * @param {?string} the student's id or null
 * @return {{ id: string, fullName: string }[]} an array of objects with a student Id and the student's fullName
 */
export function getOtherStudents(
	state: ReduxStore,
	studentId: ?string
): { id: string, fullName: string }[] {
	if (!studentId) return []
	const allStudents = mapObject(getStudents(state), object => object)
	const otherStudents = []
	allStudents
		.filter(student => student.id !== studentId)
		.forEach(student => {
			const fullName = getStudentName(state, student.id)
			if (student.id !== studentId && fullName) otherStudents.push({ id: student.id, fullName })
		})
	return otherStudents
}

export function getClassQuestionStatus(
	store: ReduxStore,
	screenType: string
): { isActive: boolean, id: ?string } {
	const screen = getScreen(store)
	if (screen && screen.type === screenType) {
		return {
			isActive: true,
			id: screen._actionId,
		}
	} else
		return {
			isActive: false,
			id: null,
		}
}

export type FormattedResponse = {
	answer: StudentAnswer,
	question: Question,
	score: ?Score,
	requestId?: ?string,
	attempt?: ?number,
}

/**
 * getReviewCulminatingMomentAnswers - get the culminating moment logged in student answers from the analytics or fullState if analytics is not set.
 *
 * @param  {ReduxStore} store - the current redux store
 *
 * @returns {?Array<FormattedResponse[]>} - null if analytics is not set, the culminating moment answers for the logged in student grouped by culminating moment and sorted by attempt number.
 */
export function getReviewCulminatingMomentAnswers(store: ReduxStore): Array<FormattedResponse[]> {
	return (
		getCulminatingMomentQuestionsAndAnswersForStudentFromAnalytics(store) ??
		getClassQuestionsAndAnswersForStudent(store)
	)
}

/**
 * getCulminatingMomentQuestionsAndAnswersForStudentFromAnalytics - get the culminating moment logged in student answers from the analytics grouped by culminating moment.
 *
 * @param  {ReduxStore} store - the current redux store
 *
 * @returns {?Array<FormattedResponse[]>} - null if analytics is not set, the culminating moment answers for the logged in student grouped by culminating moment and sorted by attempt number.
 */
function getCulminatingMomentQuestionsAndAnswersForStudentFromAnalytics(
	store: ReduxStore
): ?Array<FormattedResponse[]> {
	const culminatingMomentQuestions = getCulminatingMomentQuestionsFromAnalytics(store)
	const culminatingMomentAnswers = getStudentCulminatingMomentAnswersFromAnalytics(
		store,
		getStudentId(store.general)
	)

	if (!culminatingMomentAnswers || !culminatingMomentQuestions) {
		return null
	}

	const questionMap = {}
	culminatingMomentQuestions.forEach(question => {
		questionMap[getQuestionKey(question.id, question.version)] = question
	})

	const questionMappingToResponse: { [questionId: string]: FormattedResponse[] } = {}
	culminatingMomentAnswers.forEach(answer => {
		const questionKey = getQuestionKey(answer.questionId, answer.questionVersion)
		const question = questionMap[questionKey]
		if (!question) {
			return
		}
		const formattedQuestion = {
			...question,
			_id: question.id,
			type: variantTypes.MULTIPLE_CHOICE,
			phrase: convertToMarkdownString(question.phrase),
			choices: question.choices.map(choice => ({
				...choice,
				_id: choice.id,
				answer: choice.correct,
				text: convertToMarkdownString(choice.text),
			})),
		}
		questionMappingToResponse[questionKey] ??= []
		questionMappingToResponse[questionKey].push({
			...answer,
			question: formattedQuestion,
			attempt: answer.answerNumber,
		})
	})

	const result = []
	Object.keys(questionMappingToResponse).forEach(key => {
		const questionData = questionMappingToResponse[key]
		questionData.sort((a, b) => (a.attempt ?? Infinity) - (b.attempt ?? Infinity))
		result.push(questionData)
	})

	return result
}

export type CollaborativeCulminatingMomentDocumentScores = {|
	issue: CreativeCanvasStationIssue,
	scores: CollaborativeCulminatingMomentAnalyticsScore,
|}

export type CollaborativeCulminatingMomentDocumentData = {|
	documentId: string,
	collaborativeCulminatingMomentData: Array<CollaborativeCulminatingMomentDocumentScores>,
|}

/**
 * getCollaborativeCulminatingMomentCanvasesFromAnalytics - get the collaborative culminating moment data (documents and scores) from analytics for the current student
 *
 * @param {ReduxStore} store - the current redux store
 *
 * @return {?Array<CollaborativeCulminatingMomentDocumentData>} - a list of the collaborative culminating moment data (documents and scores) for the current student
 */
export function getCollaborativeCulminatingMomentCanvasesFromAnalytics(
	store: ReduxStore
): ?Array<CollaborativeCulminatingMomentDocumentData> {
	const currentStudentId = getStudentId(store.general)
	if (!currentStudentId) {
		return
	}

	const classAnalytics = store.analytics?.analytics
	if (!classAnalytics) {
		return
	}

	const studentDocuments = classAnalytics?.studentAnalytics?.[currentStudentId]?.creativeCanvases
	if (!studentDocuments) {
		return
	}

	const lookup: {
		[collaborativeCulminatingMomentId: string]: {
			documentIdToIssue: {
				[documentId: string]: CollaborativeCulminatingMomentDocumentScores,
			},
		},
	} = {}
	Object.keys(classAnalytics.collaborativeCulminatingMoments ?? {}).forEach(
		collaborativeCulminatingMomentId => {
			const collaborativeCulminatingMomentData =
				classAnalytics.collaborativeCulminatingMoments?.[collaborativeCulminatingMomentId]
			if (!collaborativeCulminatingMomentData) {
				return
			}

			const issueLookup = {}
			collaborativeCulminatingMomentData.issues.forEach(issue => {
				issueLookup[issue.id] = issue
			})

			collaborativeCulminatingMomentData.documents.forEach(documentData => {
				const documentIssue = issueLookup[documentData.issueId]
				if (!documentIssue) {
					return
				}
				lookup[collaborativeCulminatingMomentId] ??= { documentIdToIssue: {} }
				lookup[collaborativeCulminatingMomentId].documentIdToIssue[documentData.documentId] = {
					issue: documentIssue,
					scores: documentData.scores,
				}
			})
		}
	)

	let result = []
	studentDocuments.forEach(({ documentId, collaborativeCulminatingMomentIds }) => {
		let collaborativeCulminatingMomentData = []
		collaborativeCulminatingMomentIds.forEach(collaborativeCulminatingMomentId => {
			const documentDataForCollaborativeCulminatingMoment =
				lookup[collaborativeCulminatingMomentId]?.documentIdToIssue[documentId]

			if (!documentDataForCollaborativeCulminatingMoment) {
				return
			}
			collaborativeCulminatingMomentData.push(documentDataForCollaborativeCulminatingMoment)
		})
		if (collaborativeCulminatingMomentData.length === 0) {
			return
		}
		result.push({ documentId, collaborativeCulminatingMomentData })
	})

	return result
}

/**
 * Gets the culminating moment questions and student answers from the fullState for the logged in student. Grouped by the culminating moment question.
 *
 * @return (Array<FormattedResponse[]>) - the culminating moment answers grouped by culminating moment and sorted by attempt number.
 */
function getClassQuestionsAndAnswersForStudent(store: ReduxStore): Array<FormattedResponse[]> {
	const studentId = getStudentId(store.general)
	const culminatingMomentData = getCulminatingMomentDataFromMission(getFullState(store))
	const questionsAndAnswers: Array<FormattedResponse[]> = []
	if (!culminatingMomentData) {
		return []
	}
	Object.keys(culminatingMomentData).forEach(culminatingMomentId => {
		const action = getQuestionActions(store)[culminatingMomentId]
		const culminatingMomentQuestionData = action?.questionInfo

		if (!culminatingMomentQuestionData) {
			return
		}

		const correctOptionIds = new Set()
		const formattedQuestion: Question = {
			_id: culminatingMomentQuestionData._id,
			type: variantTypes.MULTIPLE_CHOICE,
			default: true,
			version: 1,
			phrase: convertToMarkdownString(culminatingMomentQuestionData.phrase),
			choices: culminatingMomentQuestionData.options.map(option => {
				if (option.correct) {
					correctOptionIds.add(option._id)
				}
				return {
					_id: option._id,
					answer: option.correct,
					text: convertToMarkdownString(option.text),
					media: option.imageUrl ? [{ type: 'IMAGE', url: option.imageUrl }] : [],
				}
			}),
		}

		const singleStudentResponses: FormattedResponse[] = (
			(studentId && culminatingMomentData[culminatingMomentId]?.responses?.[studentId]) ||
			[]
		).map(optionId => ({
			answer: { type: variantTypes.MULTIPLE_CHOICE, value: optionId },
			score: optionId
				? { score: correctOptionIds.has(optionId) ? 1 : 0, perfectScore: 1 }
				: undefined,
			question: formattedQuestion,
		}))
		if (singleStudentResponses.length) {
			questionsAndAnswers.push(singleStudentResponses)
		}
	})
	return questionsAndAnswers
}
/**
 * getStudentQuestionDataFromAnalytics - get the student question responses from analytics.
 *
 * @param  {ReduxStore} store - the current redux store
 *
 * @returns {?Array<FormattedResponse[]>} - the student's questions answers from analytics grouped by question and sorted by attemptNumber, null if analytics is not set.
 */
export function getStudentQuestionDataFromAnalytics(
	store: ReduxStore
): ?Array<FormattedResponse[]> {
	const studentAnswersFromAnalytics = getStudentQuestionAnswersFromAnalytics(
		store,
		getStudentId(store.general)
	)
	const questions = getAllQuestions(store)

	if (!studentAnswersFromAnalytics || !questions) {
		return null
	}

	const questionLookup = {}
	questions.forEach(question => (questionLookup[question._id] = question))

	const questionToQuestionInfoMap: { [questionId: string]: FormattedResponse[] } = {}
	studentAnswersFromAnalytics.forEach(answer => {
		const question = questionLookup[answer.questionId]

		if (!question) {
			return
		}

		questionToQuestionInfoMap[answer.questionId] ??= []
		questionToQuestionInfoMap[answer.questionId].push({
			score: answer.score,
			answer: answer.answer,
			attempt: answer.answerNumber ?? questionToQuestionInfoMap[answer.questionId].length + 1,
			question,
		})
	})
	Object.keys(questionToQuestionInfoMap).forEach(questionId => {
		questionToQuestionInfoMap[questionId].sort(
			(a, b) => (a.attempt ?? Infinity) - (b.attempt ?? Infinity)
		)
	})

	return Object.keys(questionToQuestionInfoMap).map(
		questionId => questionToQuestionInfoMap[questionId]
	)
}

/**
 * Return the analytics store from the current redux state
 * @param {ReduxStore} store
 */
export function getAnalyticsStore(store: ReduxStore): AnalyticsStore {
	return store.analytics
}

/** Gets the screen timer associated with the culminating moment screen */
export function getScreenTimerForCulminatingMoment(store: ReduxStore): ?string {
	const screen = getScreen(store)
	if (screen && screen.type === 'CULMINATING_MOMENT') {
		return screen.timerId
	} else return null
}

export function classQuestionVotingComplete(store: ReduxStore): boolean {
	return Boolean(getCulminatingMomentResultFromMission(getFullState(store)))
}

export function getScreen(store: ReduxStore): ?Screen {
	return getScreenFromMission(getFullState(store))
}

export function getIsCulminatingMoment(store: ReduxStore): boolean {
	return (
		getClassQuestionStatus(store, 'CULMINATING_MOMENT').isActive ||
		getCollaborativeCulminatingMomentStep(store)?.status ===
			COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.VOTING
	)
}

export function getIsQuestionSlot(store: ReduxStore): boolean {
	const missionType = getMissionType(store)
	if (missionType === MISSION_TYPES.FOUR_PLUS) {
		return getIsQuestionSlotJrPlus(store)
	} else {
		return getClassQuestionStatus(store, 'QUESTION_SLOT').isActive
	}
}

export function getClassQuestionInfo(store: ReduxStore): ?QuestionInfo {
	const screen = getScreen(store)
	if (screen && screen.type === 'CULMINATING_MOMENT') {
		return screen.questionInfo
	}
}

/**
 * Get the student's normalized question responses, depending on the mission type. Prioritizes getting question responses from analytics
 * but will fallback to fullState if analytics is not given.
 *
 * @param {ReduxStore} store - the current redux store
 *
 * @return {Array<FormattedResponse[]>} - the students question answers grouped by question and sorted by attempt number.
 */
export function getStudentAnswersToQuestions(store: ReduxStore): Array<FormattedResponse[]> {
	const analyticsAnswers = getStudentQuestionDataFromAnalytics(store)
	if (analyticsAnswers) {
		return analyticsAnswers
	}
	return getMissionType(store) === JUNIOR
		? makeJrQuestionStudentResponses(store)
		: makeJrPlusQuestionStudentResponses(store)
}

/**
 * Gets the student's jrPlus question responses, and transforms the data to be in a normalized
 * format.
 */
function makeJrPlusQuestionStudentResponses(store: ReduxStore): Array<FormattedResponse[]> {
	const studentId = getStudentId(store.general)
	const questionData = getJrPlusStudentQuestionData(store, studentId)
	const questions = getAllQuestions(store)
	if (!studentId || !questions?.length || !questionData) {
		return []
	}

	const questionLookup = {}
	questions.forEach(question => (questionLookup[question._id] = question))

	const questionToQuestionInfoMap: { [questionId: string]: FormattedResponse[] } = {}

	Object.keys(questionData.questionResponses).forEach(questionId => {
		const question = questionLookup[questionId]
		const responses = values(questionData.questionResponses[questionId])

		if (!question || !responses.length) {
			return
		}

		responses?.sort((a, b) => (a.responseTimestamp ?? Infinity) - (b.responseTimestamp ?? Infinity))

		questionToQuestionInfoMap[questionId] ??= []
		questionToQuestionInfoMap[questionId].push(
			...responses.map((response, index) => ({
				score: response.score,
				answer: response.answer,
				attempt: index + 1,
				question,
			}))
		)
	})

	return Object.keys(questionToQuestionInfoMap).map(key => questionToQuestionInfoMap[key])
}

/**
 * Gets the student's jr question responses, and transforms the data to be in a normalized
 * format.
 */
function makeJrQuestionStudentResponses(store: ReduxStore): Array<FormattedResponse[]> {
	const studentId = getStudentId(store.general)
	if (!studentId) {
		return []
	}

	const questionData = getFullState(store)?.questionData.answers[studentId]
	const questions = getAllQuestions(store)

	if (!questions?.length || !questionData) {
		return []
	}

	const questionLookup = {}
	questions.forEach(question => (questionLookup[question._id] = question))

	const questionToQuestionInfoMap: { [questionId: string]: FormattedResponse[] } = {}

	Object.keys(questionData).forEach(questionId => {
		const question = questionLookup[questionId]

		if (!question) {
			return
		}

		const questionResponsesArray = questionData[questionId]
		questionResponsesArray?.sort(
			(a, b) => (a.responseTimestamp ?? Infinity) - (b.responseTimestamp ?? Infinity)
		)

		questionToQuestionInfoMap[questionId] ??= []
		questionToQuestionInfoMap[questionId].push(
			...questionResponsesArray.map((response, index) => ({
				score: response.score,
				answer: response.answer,
				attempt: index + 1,
				question,
			}))
		)
	})

	return Object.keys(questionToQuestionInfoMap).map(id => questionToQuestionInfoMap[id])
}

/**
 * Gets responses for each question on a K-3 mission
 * @param {QuestionAnswerStore} answerData
 * @param {Question[]} baseQuestions
 * @returns
 */
function k3_getResponsesPerQuestion(
	answerData: QuestionAnswerStore,
	baseQuestions: Question[]
): { question: Question, responses: NormalizedQuestionResponse[] }[] {
	return baseQuestions.map((question: Question) => {
		const questionId = question._id
		const resultResponses: NormalizedQuestionResponse[] = []
		Object.keys(answerData).forEach(studentId => {
			const responses = answerData[studentId][questionId]
			if (!answerData[studentId][questionId]) {
				return
			}
			responses.forEach(response => {
				resultResponses.push({
					id: response.id,
					requestId: response.requestId,
					studentId,
					timeStamp: response.responseTimestamp,
					answer: response.answer,
					score: response.score,
				})
			})
		})
		return {
			question,
			responses: resultResponses,
		}
	})
}
/**
 * Gets responses for each question on a 4+ mission
 * @param {JuniorPlusQuestionData} questionData
 * @param {Question[]} baseQuestions
 * @returns {{ question: Question, responses: NormalizedQuestionResponse[] }[]}
 */
function fourPlus_getResponsesPerQuestion(
	questionData: JuniorPlusQuestionData,
	baseQuestions: Question[]
): { question: Question, responses: NormalizedQuestionResponse[] }[] {
	return baseQuestions.map((question: Question) => {
		const questionId = question._id
		const resultResponses: NormalizedQuestionResponse[] = []
		Object.keys(questionData).forEach(studentId => {
			if (!questionData[studentId] || !questionData[studentId].questionResponses[questionId]) {
				return
			}
			const responses = questionData[studentId].questionResponses[questionId]
			Object.keys(responses).forEach(responseId => {
				const response = responses[responseId]
				resultResponses.push({
					id: responseId,
					requestId: null,
					studentId,
					timeStamp: response.responseTimestamp,
					answer: response.answer,
					score: response.score,
				})
			})
		})
		return {
			question,
			responses: resultResponses,
		}
	})
}

/**
 * maps each question on the mission to all the student responses made for each respective question
 */
export function getResponsesPerQuestion(state: ReduxStore): Array<QuestionAndResponses> {
	let missionState = getFullState(state)
	let missionType = getMissionType(state)
	let questions = state.staticData.questions

	if (!questions) {
		return []
	}
	if (missionType === MISSION_TYPES.K_THROUGH_3) {
		// $FlowFixMe[prop-missing]
		// $FlowFixMe[incompatible-exact]
		// $FlowFixMe[incompatible-type] We know that missionState is a k-3 mission type
		const k3MissionState: JuniorMissionFullState = missionState
		return k3_getResponsesPerQuestion(k3MissionState.questionData.answers, questions)
	}
	if (missionType === MISSION_TYPES.FOUR_PLUS) {
		// $FlowFixMe[prop-missing]
		// $FlowFixMe[incompatible-exact]
		// $FlowFixMe[incompatible-type] We know that missionState is a 4+ mission type
		const fourPlusMissionState: JuniorPlusMissionFullState = missionState
		return fourPlus_getResponsesPerQuestion(fourPlusMissionState.questionData, questions)
	}
	return []
}

export function getLoginError(state: ReduxStore): ?LoginError {
	return state.general.loginError
}

export function getAreAllSurveysCompleted(state: ReduxStore): boolean {
	return areSurveysCompleted(getFullState(state))
}

/**
 * getQuestionKey - get a string representing a key for the given question
 *
 * @param  {string} questionId - the question's id
 * @param  {number} questionVersion - the question's version
 *
 * @returns string - the key for the given question
 */
function getQuestionKey(questionId: string, questionVersion: number): string {
	return `${questionId}-${questionVersion}`
}

const COLLABORATIVE_CULMINATING_MOMENT_GRADING_STEPS = new Set([
	COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_ALL,
	COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_HIDDEN,
	COLLABORATIVE_CULMINATING_MOMENT.SCREEN_STATUS.GRADING_UNHIDDEN,
])

/**
 * getIsCreativeCanvasGradingStep - returns true if the current collaborative culminating moment screen is on a grading step (undefined if not on collaborative culminating moment screen)
 *
 * @param {ReduxStore} state - the current redux state
 *
 * @return {?boolean}
 */
export function getIsCreativeCanvasGradingStep(state: ReduxStore): ?boolean {
	const screen = getScreen(state)
	if (screen?.type !== 'COLLABORATIVE_CULMINATING_MOMENT_SCREEN') {
		return
	}
	return COLLABORATIVE_CULMINATING_MOMENT_GRADING_STEPS.has(screen.currentStep.status)
}

/**
 * getRanActionIds - get the game (simulation) actions which have ran during the mission in the order they were ran
 *
 * @param {ReduxStore} state - the current redox store
 *
 * @return {string[]} - the ids of the game (simulation) actions ran during the mission
 */
export function getRanActionIds(state: ReduxStore): string[] {
	return getFullState(state)?.launchpad.ranGameActionIds ?? []
}
