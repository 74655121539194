import { getStudentId } from '../../stores/general'
import { getTimer } from '../sharedSelectors'
import type { ReduxStore } from '../../rootReducer'
import type { Coordinates as Point, RobotAction, Message } from '../../../types'
import type { Timer, StudentDeckData, AlertStatus } from '@mission.io/mission-toolkit'
import { getTraining } from './training'

export function getStudentDeckData(state: ReduxStore): ?StudentDeckData {
	const trainingRepairs = getTraining(state)?.repairsPhase
	if (trainingRepairs && trainingRepairs.status === 'ACTIVE') {
		return trainingRepairs.studentDeckData
	}
	const studentId = getStudentId(state.general)
	if (studentId) {
		return state.jrPlusState.stationData.decks.studentDecks[studentId]
	}
	return null
}

export function getRobotPreciseLocation(state: ReduxStore): Point {
	const studentDeckData = getStudentDeckData(state)
	if (studentDeckData) {
		return studentDeckData.robot.location.type === 'PRECISE'
			? // $FlowFixMe if type===PRECISE, then we know it has a point
			  studentDeckData.robot.location.point
			: { x: 0, y: 0 }
	}
	return { x: 0, y: 0 }
}

export function getCurrentRoomIdOfRobot(state: ReduxStore): ?string {
	const studentDeckData = getStudentDeckData(state)
	if (studentDeckData) {
		return studentDeckData.robot.location.type === 'PRECISE'
			? studentDeckData.robot.location.roomId
			: null
	}
	return null
}

export function getTravellingPathForDecks(state: ReduxStore): ?(Point[]) {
	const studentDeckData = getStudentDeckData(state)
	if (studentDeckData) {
		return studentDeckData.robot.location.type === 'TRAVELLING'
			? // $FlowFixMe if type===TRAVELLING, then we know it has a path
			  studentDeckData.robot.location.path
			: null
	}
	return null
}

export function getTravelingTimer(state: ReduxStore): ?Timer {
	const studentDeckData = getStudentDeckData(state)
	if (!studentDeckData) {
		return null
	}
	const robotLocation = studentDeckData.robot.location
	const timerId = robotLocation.type === 'TRAVELLING' ? robotLocation.timerId : null
	if (!timerId) {
		return null
	}
	return getTimer(state, timerId)
}

export function isRobotTravelling(state: ReduxStore): boolean {
	const studentDeckData = getStudentDeckData(state)
	if (studentDeckData) {
		return studentDeckData.robot.location.type === 'TRAVELLING'
	}
	return false
}

export function getActiveAlerts(state: ReduxStore): AlertStatus[] {
	const studentDeckData = getStudentDeckData(state)
	if (!studentDeckData) return []
	return studentDeckData.alerts.filter(alert => !alert.fixed)
}

export function getActiveAlertTotal(state: ReduxStore): number {
	const alerts = getActiveAlerts(state)
	return alerts.length
}

export function getRobotAction(state: ReduxStore): ?RobotAction {
	const studentDeckData = getStudentDeckData(state)
	if (!studentDeckData) return null
	return studentDeckData.robot.action
}

export function getDeckMessages(state: ReduxStore): Message[] {
	const studentDeckData = getStudentDeckData(state)
	if (!studentDeckData) return []
	return studentDeckData.alerts
		.filter((alert: AlertStatus) => alert.fixed)
		.map(alert => getMessageForAlertStatus(state, alert))
}

function getMessageForAlertStatus(state: ReduxStore, alertStatus: AlertStatus): Message {
	const location = state.staticData.rooms[alertStatus.roomId].point
	const alert = state.staticData.alerts[alertStatus.alertKey]
	return {
		text: alert?.fixMessage || 'Fixed!',
		point: location,
		status: 'success',
		alertId: alertStatus.id,
	}
}
