import { JR_PLUS_PATCH, JR_PLUS_FULL_STATE } from '../actionTypes'
import type { Patch } from 'immer'
import { applyPatches } from '@mission.io/mission-player'

import type { JuniorPlusMissionFullState } from '@mission.io/mission-toolkit'
import {
	DEFENSE_STATION,
	TRACTOR_BEAM_STATION,
	QUESTION_PHASE,
	MISSION_PHASES,
} from '@mission.io/mission-toolkit/constants'

/**
 * Gets the initial state for the `jrPlusState` redux store
 */
export function getInitialState(): JuniorPlusMissionFullState {
	// $FlowFixMe add waiters to the full state in mission toolkit
	return {
		boostData: { questionTimerLength: 0 },
		deltaClock: {
			running: false,
			lastRunTime: -1,
			runningTimers: [],
			pausedTimers: {},
		},
		healthTasks: {
			tasks: {},
			surveys: {},
			isActive: true,
		},
		extraContextualData: { data: [] },
		points: { studentPoints: {}, crewPoints: { GENERAL: 0 } },
		questionData: {},
		questionPhase: { status: QUESTION_PHASE.STATUS.INACTIVE },
		stationData: {
			creativeCanvas: {
				currentCanvasId: null,
				canvases: {},
				meta: {
					studentInfo: {},
				},
			},
			communication: {
				_actionId: null,
				active: false,
				signalStrength: 0,
				targetSignalStrength: 0,
				targets: {},
				mapObject: null,
				actionObjectInfo: null,
			},
			decks: {
				_actionId: null,
				timersToRespawnAlerts: [],
				healthPerRepair: 0,
				active: false,
				timerId: null,
				studentDecks: {},
			},
			defense: {
				status: DEFENSE_STATION.STATUS.INACTIVE,
			},
			engineering: {
				panels: {},
				components: {},
				interactive: {},
			},
			power: {
				_actionId: null,
				active: false,
				studentValues: {},
				startTime: 0,
				min: 0,
				max: 1,
				mapObject: null,
				actionObjectInfo: null,
				completionTimerId: null,
			},
			sensor: {
				visitedMaps: [],
				collectedData: {},
				studentVotes: { contentId: {} },
			},
			thrusters: {
				studentData: {},
				isActive: false,
			},
			tractorBeam: {
				status: TRACTOR_BEAM_STATION.STATUS.INACTIVE,
			},
			transporter: {
				_actionId: null,
				status: 'OFFLINE',
				groupRequiredSegmentCount: 0,
				studentData: {},
				lastResetTimestamp: 0,
				mapObject: null,
				actionObjectInfo: null,
			},
		},
		culminatingMomentData: {},
		map: null,
		mapInfo: {},
		students: {},
		teams: {},
		training: { status: 'INACTIVE' },
		pausedState: { state: 'PLAYING' },
		shipHealth: 100,
		death: { status: 'ALIVE' },
		waiters: {},
		missionPhase: MISSION_PHASES.TRAINING,
		surveys: null,
		launchpad: {
			screen: null,
			overlays: [],
			vocalTracks: [],
			specialEffects: {},
			ambiance: {
				id: 'NONE',
				_actionId: null,
				startTime: Date.now(),
			},
			song: null,
			visitedPhasesAndCheckpoints: [],
			mainObjective: { objectiveText: '', teacherInfo: '' },
			ranGameActionIds: [],
		},
		navigation: {
			actionId: null,
			name: null,
			description: null,
			data: null,
			fullData: null,
		},
		literacyEvents: {
			studentStates: {},
			seenEvents: {},
			studentFeedbackRequests: {},
		},
		investigations: [],
	}
}

export default function jrStateReducer(
	state: JuniorPlusMissionFullState = getInitialState(),
	action:
		| { type: 'JR_PLUS_PATCH', payload: Patch[] }
		| { type: 'JR_PLUS_FULL_STATE', payload: JuniorPlusMissionFullState }
): JuniorPlusMissionFullState {
	switch (action.type) {
		case JR_PLUS_FULL_STATE: {
			const updatedState = action.payload
			return updatedState
		}
		case JR_PLUS_PATCH: {
			const patches = action.payload
			// $FlowFixMe[prop-missing] its ok that JrShared does not have some of JuniorPlusMissionFullState properties, this is a flow error that will require a change in mission-player
			return applyPatches(state, patches)
		}
		default:
			return state
	}
}
