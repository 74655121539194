import { JR_PATCH, JR_FULL_STATE } from '../actionTypes'
import type { Patch } from 'immer'
import { applyPatches } from '@mission.io/mission-player'
import type { JuniorMissionFullState } from '@mission.io/mission-toolkit'
import { MISSION_PHASES } from '@mission.io/mission-toolkit/constants'

/**
 * Gets the initial state for the `jrState` redux store
 */
export function getInitialState(): JuniorMissionFullState {
	return {
		deltaClock: {
			running: false,
			lastRunTime: -1,
			runningTimers: [],
			pausedTimers: {},
		},
		stationData: {
			communication: {
				_actionId: null,
				studentHits: {},
				groupRequiredConnectionCount: null,
				actionObjectInfo: null,
			},
			defense: {
				_actionId: null,
				status: 'OFFLINE',
				studentHits: {},
				studentRequiredHits: 0,
				actionObjectInfo: null,
			},
			power: {
				_actionId: null,
				targetPower: 0,
				studentValues: {},
				isActive: false,
				actionObjectInfo: null,
				completionTimerId: null,
			},
			thrusters: {
				studentData: {},
				isActive: false,
			},
			tractorBeam: {
				_actionId: null,
				status: 'OFFLINE',
				studentHits: {},
				studentRequiredHits: 0,
				actionObjectInfo: null,
			},
			transporter: {
				_actionId: null,
				status: 'OFFLINE',
				groupRequiredSegmentCount: 0,
				studentData: {},
				lastResetTimestamp: 0,
				actionObjectInfo: null,
			},
			scanner: {
				isActive: false,
				groupRequiredScans: 0,
				studentScans: {},
				actionObjectInfo: null,
			},
			repair: {
				completionTimerId: null,
				healthPerRepair: 0,
				active: false,
				studentFixes: {},
				brokenSystems: {},
				repairsPerStudent: 0,
			},
			creativeCanvas: {
				currentCanvasId: null,
			},
		},
		culminatingMomentData: {},
		points: { studentPoints: {}, crewPoints: { GENERAL: 0 } },
		questionData: { currentQuestion: null, answers: {}, questions: {} },
		students: {},
		pausedState: { state: 'PLAYING' },
		shipHealth: 100,
		death: { status: 'ALIVE' },
		missionPhase: MISSION_PHASES.ACTIVE,
		surveys: null,
		launchpad: {
			screen: null,
			overlays: [],
			vocalTracks: [],
			specialEffects: {},
			ambiance: {
				id: 'NONE',
				_actionId: null,
				startTime: Date.now(),
			},
			song: null,
			visitedPhasesAndCheckpoints: [],
			mainObjective: { objectiveText: '', teacherInfo: '' },
			ranGameActionIds: [],
		},
		navigation: {
			actionId: null,
			name: null,
			description: null,
			data: null,
			fullData: null,
		},
		literacyEvents: {
			seenEvents: {},
			studentStates: {},
			studentFeedbackRequests: {},
		},
		investigations: [],
	}
}

export default function jrStateReducer(
	state: JuniorMissionFullState = getInitialState(),
	action:
		| { type: 'JR_PATCH', payload: Patch[] }
		| { type: 'JR_FULL_STATE', payload: JuniorMissionFullState }
): JuniorMissionFullState {
	switch (action.type) {
		case JR_FULL_STATE: {
			const fullState = action.payload
			return fullState
		}
		case JR_PATCH: {
			const patches = action.payload
			return applyPatches(state, patches)
		}
		default:
			return state
	}
}
