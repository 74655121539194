import classnames from 'classnames'
import React from 'react'

export const Overlay = ({
	children,
	className,
	...props
}: {
	className?: string,
	children?: React$Node,
}): React$Node => (
	<div {...props} className={classnames('absolute z-10 w-full h-full bg-black/75', className)}>
		{children}
	</div>
)
