/**
 * A hack to handle errors caused by Google Translate. The error in question is described in detail in this issue https://github.com/facebook/react/issues/11538.
 * Essentially, a few browser api functions that are called by React, namely `insertBefore` and `removeChild`, can fail when they are called after Google
 * Translate swaps out text nodes during translation. This causes a full on error that we want to avoid. This function is a hack to handle that error.
 * Note that this hack will introduce a slight performance hit.
 *
 * The exact conditions for the error to happen can be found here: https://github.com/facebook/react/issues/11538#issuecomment-390386520
 * The code in this function is the code in this comment(https://github.com/facebook/react/issues/11538#issuecomment-417504600),
 * modified to run the original first and only fallback if it fails. Our version also still allows the functions to fail.
 *
 * We may want to remove this if we remove our dependence on Google Translate.
 */
export function hackToHandleGoogleTranslateError() {
	// $FlowIgnore[prop-missing]
	if (typeof Node !== 'function' || !Node.prototype) {
		return
	}

	// Fix removeChild
	// $FlowIgnore[incompatible-use]
	const originalRemoveChild = Node.prototype.removeChild
	// $FlowIgnore[incompatible-use]
	Node.prototype.removeChild = function(child) {
		try {
			return originalRemoveChild.apply(this, arguments)
		} catch (error) {
			if (child.parentNode !== this) {
				console.error('Cannot remove a child from a different parent', child, this)
				return child
			}
			throw error
		}
	}

	// Fix insertBefore
	// $FlowIgnore[incompatible-use]
	const originalInsertBefore = Node.prototype.insertBefore
	// $FlowIgnore[incompatible-use]
	Node.prototype.insertBefore = function(newNode, referenceNode) {
		try {
			return originalInsertBefore.apply(this, arguments)
		} catch (error) {
			if (referenceNode && referenceNode.parentNode !== this) {
				console.error(
					'Cannot insert before a reference node from a different parent',
					referenceNode,
					this
				)
				return newNode
			}
			return originalInsertBefore.apply(this, arguments)
		}
	}
}
