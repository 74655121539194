import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { sendMessage } from '../store/stores/webSocket'
import type { TractorBeamStatus, DefenseStatus } from '../types'
import type { ReduxStore } from '../store/rootReducer'
import { isCommunicationActive, isPowerActive, isScannerActive } from '../store/selectors/jrState'
import { isThrustersActive, isRepairsActive } from '../store/selectors/sharedSelectors'
import { isPowerActive as isPlusPowerActive } from '../store/selectors/jrPlusState/power'
import { isCommunicationActive as isPlusCommunicationActive } from '../store/selectors/jrPlusState/communication'
import { getMissionType, isPaused, JUNIOR_PLUS } from '../store/stores/general'
import { setIsDebugging, getIsDebugging } from '../store/stores/settings'
import './HackerActions.css'

type Props = {
	sendMessage: (type: string, payload: any) => void,
	setIsDebugging: typeof setIsDebugging,
	communicationActiveStatus: boolean,
	scannerActiveStatus: boolean,
	repairActiveStatus: boolean,
	powerActiveStatus: boolean,
	thrusterActiveStatus: boolean,
	missionType: ?string,
	isPaused: boolean,
	isDebugging: boolean,
}

type State = {
	damageAmount: number,
	tractorBeamStatus: TractorBeamStatus,
	defenseStatus: DefenseStatus,
}

class HackerActions extends Component<Props, State> {
	state: State = {
		damageAmount: 10,
		tractorBeamStatus: 'ASTEROID',
		defenseStatus: 'ENEMY_SHIP',
	}

	render() {
		return (
			<div className="HackerActions">
				<div className="action">
					<button onClick={() => this.sendHack('DAMAGE-SHIP', Number(this.state.damageAmount))}>
						Damage Ship -{this.state.damageAmount}
					</button>
					<input
						className="fill"
						type="number"
						value={this.state.damageAmount}
						onChange={e => {
							this.setState({ damageAmount: e.target.value })
						}}
					/>
				</div>

				<div className="action">
					<button
						onClick={() => this.sendHack('THRUSTERS-RESET', !this.props.thrusterActiveStatus)}>
						Turn thrusters {this.props.thrusterActiveStatus ? 'off' : 'on'}
					</button>
				</div>
				<div className="action">
					<button onClick={() => this.sendHack('REPAIR-RESET', !this.props.repairActiveStatus)}>
						{this.props.repairActiveStatus ? 'Deactivate Repairs' : 'Activate Repairs'}
					</button>
				</div>
				<div className="action">
					<button
						onClick={() => {
							const payload =
								this.props.missionType === JUNIOR_PLUS ? { max: 0.6, min: 0.4 } : { targetPower: 5 }
							this.sendHack('POWER-SET', { status: !this.props.powerActiveStatus, ...payload })
						}}>
						Turn power {this.props.powerActiveStatus ? 'off' : 'on'}
					</button>
				</div>
				<div className="action">
					<button onClick={() => this.sendHack('TRANSPORTER-RESET')}>Reset Transporter</button>
				</div>
				<div className="action">
					<button
						onClick={() => {
							this.sendHack(
								this.props.missionType === JUNIOR_PLUS
									? 'PLUS-COMMUNICATION-SET'
									: 'COMMUNICATION-SET',
								!this.props.communicationActiveStatus
							)
						}}>
						Turn {this.props.communicationActiveStatus ? 'off' : 'on'}
						{this.props.missionType === JUNIOR_PLUS ? ' communications' : ' the phone'}
					</button>
				</div>
				<div className="action">
					<select
						value={this.state.tractorBeamStatus}
						onChange={e => {
							this.setState({ tractorBeamStatus: e.target.value })
						}}>
						<option value={'OFFLINE'}>Offline</option>
						<option value={'ASTEROID'}>Asteroid</option>
						<option value={'ENEMY_SHIP'}>Enemy Ship</option>
						<option value={'TARGET'}>Target</option>
					</select>
					<button onClick={() => this.sendHack('TRACTOR-BEAM-SET', this.state.tractorBeamStatus)}>
						{this.state.tractorBeamStatus
							? `Set Tractor Beam to ${this.state.tractorBeamStatus}`
							: 'Turn off Tractor Beam'}
					</button>
				</div>
				<div className="action">
					<button onClick={() => this.sendHack('SCANNER-RESET', !this.props.scannerActiveStatus)}>
						{this.props.scannerActiveStatus ? 'Turn off Scanner' : 'Turn on Scanner'}
					</button>
				</div>
				<div className="action">
					<button onClick={() => this.sendHack('CREATIVE-CANVAS')}>
						Initialize creative canvas
					</button>
				</div>
				<div className="action">
					<select
						value={this.state.defenseStatus}
						onChange={e => {
							this.setState({ defenseStatus: e.target.value })
						}}>
						<option value={'OFFLINE'}>Offline</option>
						<option value={'ASTEROID'}>Asteroid</option>
						<option value={'ENEMY_SHIP'}>Enemy Ship</option>
						<option value={'TARGET'}>Target</option>
					</select>
					<button onClick={() => this.sendHack('DEFENSE-SET', this.state.defenseStatus)}>
						{this.state.defenseStatus
							? `Set Defense to ${this.state.defenseStatus}`
							: 'Turn off Defense'}
					</button>
					<div className="action">
						<button onClick={() => this.sendHack('RESET-ALL')}>RESET ALL</button>
					</div>
					<div className="action">
						<button onClick={() => this.sendHack('TOGGLE-PAUSE', !this.props.isPaused)}>
							{this.props.isPaused ? 'Play ' : 'Pause '}Mission
						</button>
					</div>
					<div className="action">
						<button onClick={() => this.sendHack('START-TRAINING')}>Start Training</button>
					</div>
					<div className="action">
						<button onClick={() => this.sendHack('START-MISSION')}>Start Mission</button>
					</div>
					<CheckBoxWrapper>
						<input
							type="checkbox"
							checked={this.props.isDebugging}
							onChange={() => this.props.setIsDebugging(!this.props.isDebugging)}
							id="debug-toggle"
						/>
						<label htmlFor="debug-toggle">Debug</label>
					</CheckBoxWrapper>
				</div>
			</div>
		)
	}

	sendHack = (type: string, payload?: any) => {
		this.props.sendMessage('HACKER-' + type, payload)
	}
}

function mapStateToProps(state: ReduxStore) {
	const missionType = getMissionType(state)
	return {
		communicationActiveStatus:
			missionType === JUNIOR_PLUS ? isPlusCommunicationActive(state) : isCommunicationActive(state),
		scannerActiveStatus: isScannerActive(state),
		repairActiveStatus: isRepairsActive(state),
		powerActiveStatus:
			missionType === JUNIOR_PLUS ? isPlusPowerActive(state) : isPowerActive(state),
		thrusterActiveStatus: isThrustersActive(state),
		missionType,
		isPaused: isPaused(state),
		isDebugging: getIsDebugging(state),
	}
}

const mapDispatchToProps = {
	sendMessage,
	setIsDebugging,
}

export default (connect(mapStateToProps, mapDispatchToProps)(HackerActions): (
	props: $Shape<{||}>
) => React$Node)

const CheckBoxWrapper = styled.div`
	color: white;
`
