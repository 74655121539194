import React from 'react'
import { ReactModal as Modal, CloseButton } from '../../basics'
import { Button } from '../../basics/Buttons.jsx'
import classnames from 'classnames'

/**
 * A modal with some default styles and props for faster customization.
 * @param props
 * @param props.onClose A function to call when the modal is closed. If provided, the modal will have a close button which
 *                        will call this function, and this function will be called when clicking on the overlay outside of the modal
 * @param props.title The modal title. Will be displayed in the header
 * @param props.buttonText The text to display on the button. If provided, the modal will have a button with this text. Overridden by `buttons`
 * @param props.buttonAction The action to call when the button is clicked. Overridden by `buttons`
 * @param props.buttons An array of buttons to display. If provided, `buttonText` and `buttonAction` will be ignored.
 * @param props.frameContent an optional boolean  when if true the modal will only take up the student frame content area, if false the modal will cover the screen.
 * @param {boolean = true} props.isOpen Whether the modal is open or not
 * @returns {React$Node}
 */
export function CustomModal({
	children,
	onClose,
	overlayStyle = {},
	style = {},
	overlayClassName = '',
	className,
	title,
	frameContent,
	buttons = [],
	isOpen = true,
}: {|
	onClose?: () => void,
	children?: React$Node,
	overlayStyle?: Object,
	style?: Object,
	overlayClassName?: string,
	className?: string,
	title?: string,
	frameContent?: boolean,
	buttons?: $ReadOnlyArray<{ text: string, onClick: () => mixed, props?: { $small?: boolean } }>,
	isOpen?: boolean,
|}): React$Node {
	return (
		<Modal
			frameContent={frameContent}
			style={{ content: style, overlay: overlayStyle }}
			className={classnames(
				'rounded-lg bg-primary-800 p-0 border-0 w-11/12 inset-center max-h-[80vh]',
				frameContent ? 'max-w-screen-sm' : 'md:w-1/2',
				className
			)}
			overlayClassName={classnames('absolute inset-0 bg-black/75 z-[1003]', overlayClassName)}
			onRequestClose={onClose}
			isOpen={isOpen}>
			{onClose && <CloseButton onClick={onClose} />}
			<div className="text-white bg-primary-700 p-6 h-full flex flex-col overflow-hidden rounded-lg">
				{title && <h2 className="mb-2 font-semibold md:text-3xl text-xl">{title}</h2>}
				{children}
				{buttons.length > 0 && (
					<div className="flex mt-6 justify-center gap-2">
						{buttons.map(({ text, onClick, props }) => (
							<Button {...props} onClick={onClick} key={text}>
								{text}
							</Button>
						))}
					</div>
				)}
			</div>
		</Modal>
	)
}

/**
 * A button that takes up the entire width of its parent and has a title and explanation. This is usually used in modals where the entire contents of the modal
 * are these buttons.
 */
export function WideButtonWithExplanation({
	title,
	explanation,
	className,
	...props
}: {
	title: string,
	explanation: string,
	className?: string,
}): React$Node {
	return (
		<Button {...props} className={classnames('block w-full', className)}>
			<div className="text-xl">{title}</div>
			<div className="text-sm font-body italic normal-case">{explanation}</div>
		</Button>
	)
}
