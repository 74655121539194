import styled from 'styled-components'
import { LIGHT_PURPLE, BOOST_PHRASE_ACTIVE, PRIMARY_PURPLE } from '../../constants/styles'

export const SurveyBackground: StyledType<> = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	height: 100%;
	background: ${PRIMARY_PURPLE};
`

export const QuestionDiv: StyledType<> = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	height: 95%;
	width: 90%;
	font-size: 24px;
`

export const Title: StyledType<> = styled.div`
	letter-spacing: 2px;
	font-family: Orbitron;
	font-size: 28px;
	font-weight: 600;
	color: white;
`

export const Phrase: StyledType<> = styled.div`
	width: 90%;
	border-radius: 4px;

	font-family: proxima-nova, sans-serif;
	font-size: 32px;
	text-align: center;
	color: white;
	background: ${LIGHT_PURPLE};
`

export const Button: StyledType<> = styled.button`
	padding: 16px 40px;
	border: 0px;
	border-radius: 50px;
	font-size: inherit;
	font-family: Orbitron;
	background-color: ${BOOST_PHRASE_ACTIVE};
	color: white;
	${({ $shouldHide }) => $shouldHide && `visibility: hidden;`};
`
