import React from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { InfiniteMarquee } from './InfiniteMarquee'
import { DecalInformation } from './DecalInformation'
import { DecalExtenderFrame } from './DecalExtenderFrame'
import { fadeIn, fadeOut } from '../../../helperComponents/keyframes'
import { DivWithStyledScroll } from '../../../helperComponents'

/**
 * A frame for displaying information about a literacy event.
 */
export function InformationFrame({
	children,
	headline,
	isExiting,
	onFinishExit,
}: {
	children: React$Node,
	headline: string,
	isExiting: boolean,
	onFinishExit: () => void,
}): React$Node {
	const id = React.useId()
	return (
		<InformationFrameStyle
			$exit={isExiting}
			id={id}
			onAnimationEnd={e => {
				if (e.target.id === id) {
					isExiting && onFinishExit()
				}
			}}>
			<AnimatedFade $exit={isExiting}>
				<InfiniteMarquee title={headline}></InfiniteMarquee>
				<InformationContent>{children}</InformationContent>
				<DecalInformation />
			</AnimatedFade>
		</InformationFrameStyle>
	)
}

/**
 * An extended portion of the frame that can be used to display additional information.
 */
export function ExtenderFrame({
	style,
	children,
	isExiting,
}: {
	style?: {},
	children: React$Node,
	isExiting: boolean,
}): React$Node {
	return (
		<div css="position: relative; flex-grow:1; ">
			<ExtenderFrameStyle style={style} $exit={isExiting}>
				<div css="display: flex; align-items: center;">
					<DecalExtenderFrame />
					<div className="content-decorator">{children}</div>
				</div>
			</ExtenderFrameStyle>
		</div>
	)
}

const VERTICAL_SLIDE_IN_DURATION = 250
const CONTENT_FADE_IN_DURATION = 250
const EXTENDER_SLIDE_IN_DURATION = 250
export const TOTAL_DELAY: number =
	VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION + EXTENDER_SLIDE_IN_DURATION

const slideDown = keyframes`
from {
	height: 0%;
}
to {
	height: 100%;
}
`
const slideUp = keyframes`
	from {
		height: 100%;	

	}
	to {
		height: 0%;
	}
`

const InformationFrameStyle = styled.div.attrs(() => ({ className: 'bg-primary-600' }))`
	font-size: var(--font-size-m);
	width: 70%;
	z-index: 1;
	${({ $exit }) => css`
		animation: ${$exit ? slideUp : slideDown} ${VERTICAL_SLIDE_IN_DURATION}ms ease-in
			${$exit ? CONTENT_FADE_IN_DURATION : 0}ms 1 forwards;
	`}

	border-radius: 1rem;
	padding: 8px 8px 0px;
	position: relative;
`
const AnimatedFade = styled.div`
	${({ $exit }) => css`
		opacity: ${$exit ? 1 : 0};
		animation: ${$exit ? fadeOut : fadeIn} ${CONTENT_FADE_IN_DURATION}ms ease-in
			${$exit ? 0 : VERTICAL_SLIDE_IN_DURATION}ms 1 forwards;
	`}
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const InformationContent = styled(DivWithStyledScroll)`
	flex: 1;
	background: linear-gradient(#ddddf6, #c4feff);
	border-radius: 1rem;
	border: 4px solid var(--color-accent-aqua);
	text-align: left;
	margin-bottom: 32px;
`

const slideIn = keyframes`
from {
	left: -100%;
}
	to {
		left: 0%;
	}
`
const slideOut = keyframes`
	from {
		left: 0%;
	}
	to {
		left: -100%;
	}
`

const ExtenderFrameStyle = styled.div`
	position: absolute;
	z-index: 0;
	left: -100%;
	opacity: 0;
	${({ $exit }) =>
		$exit
			? css`
					opacity: 1;
					animation: ${slideOut} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards 0ms,
						${fadeOut} 1ms linear forwards ${EXTENDER_SLIDE_IN_DURATION}ms;
			  `
			: css`
					opacity: 0;
					animation: ${fadeIn} 1ms linear forwards
							${VERTICAL_SLIDE_IN_DURATION + 0.5 * CONTENT_FADE_IN_DURATION}ms,
						${slideIn} ${EXTENDER_SLIDE_IN_DURATION}ms ease-in forwards
							${VERTICAL_SLIDE_IN_DURATION + CONTENT_FADE_IN_DURATION}ms;
			  `}
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	.content-decorator {
		background-color: rgba(0, 255, 255, 0.5);
		border: 3px solid var(--color-accent-aqua);
		border-width: 3px 3px 3px 3px;
		// This margin moves the \`content-decorator\` over so that the media inside does not get cut off as much.
		// This is not a perfect solution, as the image still gets cuts off differently at different screen sizes.
		margin-left: 3px;
		border-radius: 0 1rem 1rem 0;
		padding: var(--spacing2x);
		padding-left: 0;

		img {
			border: 2px solid var(--color-accent-aqua);
		}
	}
`
