// @flow
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMissionCode } from '../../store/stores/staticData'
import { getMissionPhase, useStudentId } from '../../store/stores/general'
import styled from 'styled-components/macro'
import * as overrides from './connect/overrides'
import {
	FRAME_LOCK_ID,
	BACKGROUND_IMAGE_ID,
	CanvasMaterialProvider,
	useCanvasMaterial,
} from './connect/hooks/useCanvasMaterial'
import { BiUndo as Undo, BiRedo as Redo, BiLock, BiLockOpen } from 'react-icons/bi'
import { IconButton, Button } from '../basics/Buttons'
import '@tldraw/tldraw/tldraw.css'
import { ToolboxContainer, SelectButton } from './components/Toolbox'
import { useYjsStore } from './connect/hooks/useYjsStore'
import {
	Canvas as TlCanvas,
	TldrawEditor,
	ErrorScreen,
	defaultTools,
	DefaultFontStyle,
	DefaultHorizontalAlignStyle,
	DefaultColorThemePalette,
	TldrawUiContextProvider,
	useEditor,
	useValue,
	useKeyboardShortcuts,
	ContextMenu,
	type TLStoreWithStatus,
	DefaultSizeStyle,
} from '@tldraw/tldraw'
import { useId } from 'react'
import ViewportLock, { useSnapCameraBackToOrigin } from './components/ViewportLock'
import { useTextBoxesWithDynamicWidth } from './connect/hooks/useTextBoxesWithDynamicWidth'
import {
	CREATIVE_CANVAS_STATION,
	MISSION_PHASES,
	TRAINING,
} from '@mission.io/mission-toolkit/constants'
import {
	useCanvasStudentInfo,
	useTeamForDocumentId,
} from '../../store/selectors/jrPlusState/canvas'
import type { DisplayTeam } from '@mission.io/mission-toolkit'
import { sendMessage } from '../../store/stores/webSocket'
import { shapes } from './customShapes'
import { CUSTOM_FRAME_SHAPE_TYPE } from './customShapes/CustomFrameShapeUtil'
import { ExpandIcon } from './components/CanvasGrid'
import classnames from 'classnames'

DefaultFontStyle.defaultValue = 'sans'
DefaultSizeStyle.defaultValue = 's'
DefaultHorizontalAlignStyle.defaultValue = 'start'
// If any of the default colors are changed, they should be updated in @mission.io/mission-player as well.
// The default yellow from tldraw is closer to a gold color, so we change it here to a truer yellow.
DefaultColorThemePalette.lightMode.yellow.solid = '#f9e432'
// There is no brown in the default palette, so we add it here, replacing light red on the color palette.
DefaultColorThemePalette.lightMode['light-red'].solid = '#7B583D'

/**
 * A canvas wrapper which provides the user identity to the CanvasWithIdentity component.
 * @returns {React$Node}
 */
export default function CanvasWrapper({
	documentId,
	isTeacher = false,
	className,
	readOnly = false,
	propsForFullScreen,
}: {
	documentId: string,
	isTeacher?: boolean,
	className?: string,
	readOnly?: boolean,
	propsForFullScreen?: {|
		expanded?: boolean,
		setExpanded?: ((boolean => boolean) | boolean) => void,
	|},
}): React$Node {
	const missionCode = useSelector(getMissionCode)
	const studentId = useStudentId()
	const canvasStudentInfo = useCanvasStudentInfo()

	const isTraining = useSelector(state => getMissionPhase(state) === MISSION_PHASES.TRAINING)
	const identity = useMemo(() => {
		if (!documentId || !missionCode || (!studentId && !isTeacher)) return null
		return { roomId: documentId, missionCode, studentId }
	}, [documentId, missionCode, studentId, isTeacher])
	if (!identity) return null

	const isBlocked = !!studentId && !!canvasStudentInfo[studentId]?.isBlocked

	return (
		<CanvasMaterialProvider documentId={documentId}>
			{isTraining ? (
				<Canvas
					className={className}
					documentId={documentId}
					readOnly={!!readOnly}
					isTeacher={isTeacher}
					isBlocked={false}
					propsForFullScreen={propsForFullScreen}
				/>
			) : (
				<CanvasWithYjsStore
					className={className}
					identity={identity}
					readOnly={!!readOnly || isBlocked}
					isTeacher={isTeacher}
					isBlocked={isBlocked}
					propsForFullScreen={propsForFullScreen}
				/>
			)}
		</CanvasMaterialProvider>
	)
}

const CanvasWithYjsStore = ({ identity, ...props }) => {
	const backgroundImage = useCanvasMaterial().backgroundImage
	const store = useYjsStore(props.readOnly, identity, backgroundImage?.url)
	return <Canvas {...props} documentId={identity.roomId} store={store} />
}

/**
 * Canvas provided by TLDraw with custom toolbox UI.
 * @param {Identity} props.identity the identity used to connect the canvas to the correct yjs document.
 * @returns {React$Node}
 */
function Canvas({
	documentId,
	readOnly,
	className,
	store,
	isTeacher,
	isBlocked,
	propsForFullScreen,
}: {
	documentId: string,
	readOnly: boolean,
	className?: string,
	store?: ?TLStoreWithStatus,
	isTeacher: boolean,
	isBlocked: boolean,
	propsForFullScreen?: {|
		expanded?: boolean,
		setExpanded?: ((boolean => boolean) | boolean) => void,
	|},
}): React$Node {
	const [editor, setEditor] = React.useState(null)
	// If there is an error in the canvas, this state will be set to true
	const didErrorState = useState(false)
	const onMount = editor => {
		setEditor(editor)
		// Overrides
		editor.externalContentManager.createAssetFromUrl = overrides.createAssetFromUrl
	}

	useEffect(() => {
		if (editor) {
			editor.setReadOnly(readOnly)
		}
	}, [readOnly, editor])

	const status = store?.status || 'done'

	const uniqueId = useId()
	return (
		<CanvasErrorContextProvider value={didErrorState}>
			<Container
				data-testid={documentId}
				$readOnly={readOnly}
				$didError={didErrorState[0]}
				data-training-target={TRAINING.IDS.CREATIVE_CANVAS_EXPLAINED}>
				<div className={classnames('canvas-positioner', className)} id={uniqueId}>
					<TldrawEditor
						autoFocus
						shapes={shapes}
						tools={defaultTools}
						onMount={onMount}
						components={{ ErrorFallback }}
						store={store}>
						<CustomUI
							id={uniqueId}
							status={status}
							readOnly={readOnly}
							isBlocked={isBlocked}
							isTeacher={isTeacher}
							documentId={documentId}
							{...propsForFullScreen}
						/>
					</TldrawEditor>
				</div>
			</Container>
		</CanvasErrorContextProvider>
	)
}

const toolEnumToTlDrawType = {
	[CREATIVE_CANVAS_STATION.CANVAS_TOOLS.DRAW]: { type: 'draw', label: 'Draw' },
	[CREATIVE_CANVAS_STATION.CANVAS_TOOLS.IMAGE]: { type: 'image', label: 'Image' },
	[CREATIVE_CANVAS_STATION.CANVAS_TOOLS.TEXT]: { type: 'text', label: 'Text' },
	[CREATIVE_CANVAS_STATION.CANVAS_TOOLS.SHAPE]: { type: 'geo', label: 'Shape' },
}

function CustomUI({
	id,
	status,
	readOnly,
	isTeacher,
	documentId,
	isBlocked,
	expanded,
	setExpanded,
}: {
	id: string,
	status: string,
	readOnly: boolean,
	isTeacher: boolean,
	documentId: string,
	isBlocked: boolean,
	expanded?: boolean,
	setExpanded?: ((boolean => boolean) | boolean) => void,
}) {
	const material = useCanvasMaterial()

	useTextBoxesWithDynamicWidth()

	return (
		<TldrawUiContextProvider overrides={overrides.uiOverrides}>
			{!readOnly && (
				<>
					<IncludeCustomKeyboardShortcuts />
					<ToolboxContainer>
						{material.tools.map(toolEnum => {
							const { label, type } = toolEnumToTlDrawType[toolEnum]
							return (
								<SelectButton key={toolEnum} type={type}>
									{label}
								</SelectButton>
							)
						})}
						<SelectButton type="eraser">Erase</SelectButton>
						<SelectButton type="select">Select</SelectButton>
					</ToolboxContainer>
					<HeaderButtons
						{...{
							isTeacher,
							documentId,
						}}
					/>
				</>
			)}
			{isBlocked && (
				<div className="absolute inset-2 z-[--layer-overlays] text-3xl rounded-2xl bg-primary-700 text-white p-2 flex items-center">
					<BiLock size={75} />
					<span className="flex-1 text-center">
						You have been locked out of this station by your teacher
					</span>
				</div>
			)}
			<ViewportLock id={id} status={status} />
			<BackToContent id={id} />
			{setExpanded && (
				<ExpandIcon expanded={expanded} onClick={() => setExpanded(state => !state)} />
			)}
			<ContextMenu>
				<TlCanvas />
			</ContextMenu>
		</TldrawUiContextProvider>
	)
}
function IncludeCustomKeyboardShortcuts() {
	useKeyboardShortcuts()
	return null
}

/**
 * Buttons to display near the top of the canvas. Includes undo/redo buttons, and if `isTeacher` is true, a lock button.
 */
function HeaderButtons({ isTeacher, documentId }: { isTeacher?: boolean, documentId: string }) {
	const editor = useEditor()
	const [lockPopupIsOpen, setLockPopupIsOpen] = useState(false)
	const team = useTeamForDocumentId(documentId)

	const childClassNames =
		'rounded-3xl bg-primary-700 px-0.5 min-w-8 flex items-center justify-center'

	return (
		<div className="absolute right-2 top-2 z-[--layer-panels] flex gap-2">
			{isTeacher && team && (
				<div className={childClassNames + ' relative'}>
					<IconButton onClick={() => setLockPopupIsOpen(isOpen => !isOpen)}>
						<BiLock size={22} />
					</IconButton>
					{lockPopupIsOpen && <LockPopup {...{ team }} />}
				</div>
			)}
			<div className={childClassNames}>
				<IconButton
					onClick={() => {
						editor.undo()
					}}>
					<Undo size={32} className="-mr-px ml-1" />
				</IconButton>
				<IconButton
					onClick={() => {
						editor.redo()
					}}>
					<Redo size={32} className="mr-1 -ml-px" />
				</IconButton>
			</div>
		</div>
	)
}

/**
 * A popup which allows the teacher to lock/unlock students on `team` from editing the canvas.
 */
function LockPopup({ team }: { team: DisplayTeam }) {
	const dispatch = useDispatch()
	const canvasStudentInfo = useCanvasStudentInfo()

	const studentsWithLockStatus = [team.lead, ...team.students].filter(Boolean).map(student => ({
		...student,
		isLocked: canvasStudentInfo[student.id]?.isBlocked,
	}))
	const allStudentsAreLocked = studentsWithLockStatus.every(student => student.isLocked)

	return (
		<div className="text-base bg-primary-700 shadow-lg shadow-neutral/50 absolute left-0 top-full min-w-72 w-fit mt-2 p-4 rounded-xl">
			<p>
				Lock students on <b>{team.displayName} Team</b> out of editing the canvas.
			</p>
			<ul>
				{studentsWithLockStatus.map(student => {
					if (!student) return null
					return (
						<li key={student.id} className="flex justify-between items-center mb-2">
							{student.firstName}
							{student.lastName && <>&nbsp;{student.lastName[0] + '.'}</>}

							<Button
								$small
								className="flex items-center gap-2 w-24"
								onClick={() => {
									dispatch(
										sendMessage('CREATIVE_CANVAS_SET_STUDENT_DOCUMENTS_LOCK', {
											studentIds: [student.id],
											isLocked: !student.isLocked,
										})
									)
								}}>
								{student.isLocked ? (
									<>
										<span className="flex-1 flex justify-center">Unlock</span>{' '}
										<BiLockOpen size={20} />
									</>
								) : (
									<>
										<span className="flex-1 flex justify-center">Lock</span> <BiLock size={20} />
									</>
								)}
							</Button>
						</li>
					)
				})}
			</ul>
			<Button
				className="flex items-center gap-2 w-full justify-center"
				onClick={() => {
					dispatch(
						sendMessage('CREATIVE_CANVAS_SET_STUDENT_DOCUMENTS_LOCK', {
							studentIds: studentsWithLockStatus.map(student => student.id),
							isLocked: !allStudentsAreLocked,
						})
					)
				}}>
				{allStudentsAreLocked ? (
					<>
						Unlock All <BiLockOpen size={25} />
					</>
				) : (
					<>
						Lock All <BiLock size={25} />
					</>
				)}
			</Button>
		</div>
	)
}

/**
 * A component which displays a button for students to clearly see how to return to the main view of the canvas content.
 * The button will appear after a student zooms in / zooms out or pans across the canvas.
 * @param {string} props.documentId needed in order to snap the camera back to the origin. This is the css selector id of the main tldraw component.
 * @returns { React$Node }
 */
function BackToContent({ id }: { id: string }) {
	const editor = useEditor()
	const snapCameraBackToOrigin = useSnapCameraBackToOrigin(editor, id)

	const isNotLockedWithFrame = useValue(
		'is-camera-not-locked-with-frame',
		() => {
			const camera = editor.camera
			if (camera.x !== 0 || camera.y !== 0) {
				return true
			}
			return false
		},
		[editor]
	)

	return (
		isNotLockedWithFrame && (
			<Button onClick={snapCameraBackToOrigin} className="absolute left-4 top-2 z-[--layer-panels]">
				Back to <br />
				Main View
			</Button>
		)
	)
}

const Container = styled.div`
	// position relative so that z-index has an effect
	position: relative;
	z-index: 0;
	--canvas-border-radius: 32px;
	--border-width: 8px;
	--canvas-inner-border-radius: calc(var(--canvas-border-radius) - var(--border-width));

	background-color: var(--color-primary-darker);
	${({ $readOnly, $didError }) =>
		$readOnly &&
		!$didError && // allow pointer events if there was an error so the user can click the refresh button
		`.tl-container * {
			pointer-events: none !important;
		}
	`}

	border: var(--border-width) solid var(--color-primary-darker);
	border-radius: var(--canvas-border-radius);

	[data-shape-type='${CUSTOM_FRAME_SHAPE_TYPE}'] {
		opacity: 1 !important;
	}

	#${FRAME_LOCK_ID} *,
	#${BACKGROUND_IMAGE_ID} * {
		pointer-events: none !important;
	}
	--toolbox-width-without-border: 64px;
	--toolbox-width: calc(var(--toolbox-width-without-border) + var(--border-width));
	.canvas-positioner {
		// In not readonly mode, make room for the toolbox
		${({ $readOnly }) => ($readOnly ? '' : 'margin-left: var(--toolbox-width-without-border')} );
		aspect-ratio: 4 / 3;
		.tl-container {
			overflow: ${({ $readOnly }) => ($readOnly ? 'hidden' : 'visible')};
		}
	}
	.tl-error-boundary {
		z-index: var(--z-index-overlay);
	}

	${({ $readOnly }) => {
		return `
		.tl-canvas, .tl-loading {
			border-radius: ${
				$readOnly
					? 'var(--canvas-inner-border-radius)'
					: '0px var(--canvas-inner-border-radius) var(--canvas-inner-border-radius) 0px'
			};
		}
		`
	}}
`
/* *************************
 ** Canvas Error Handling **
 ***************************/

type CanvasErrorContextValue = [
	boolean,
	(didError: boolean | ((currentState: boolean) => boolean)) => void
]
const CanvasErrorContext = React.createContext()

function useCanvasErrorContext(): CanvasErrorContextValue {
	const value = React.useContext(CanvasErrorContext)
	if (!value) {
		throw new Error('useCanvasErrorContext must be used within a CanvasErrorContextProvider')
	}
	return value
}

function CanvasErrorContextProvider({
	children,
	value,
}: {
	children: React$Node,
	value: CanvasErrorContextValue,
}) {
	return <CanvasErrorContext.Provider value={value}>{children}</CanvasErrorContext.Provider>
}

/**
 * A component that will be shown in the creative canvas when an error occurs.
 */
function ErrorFallback({ error }) {
	const [, setDidError] = useCanvasErrorContext()
	useEffect(() => {
		setDidError(true)
	}, [setDidError])

	return (
		<ErrorScreen error={error}>
			<p className="text-2xl">
				There was an unexpected error displaying the Canvas. Please refresh the page to try again.
			</p>
			<Button
				onClick={() => {
					window.location.reload()
				}}>
				Refresh
			</Button>
		</ErrorScreen>
	)
}
