import type { ReduxStore } from '../rootReducer'

import type { StudentSpecificContextualDataEntry } from '@mission.io/mission-toolkit'

import { isController } from '../../components/AppSetup'
import {
	TRAINING_CANVAS_DATA,
	TRAINING_CANVAS_PROPS,
} from '../../components/CreativeCanvas/constants'
import { useStudentId } from '../stores/general'
import { getContextualData, type Map } from '@mission.io/mission-toolkit'

import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export type ContextualDataEntity = {| studentId: string, teamId?: string |} | {| teamId: string |}

/**
 * gets the contextual data for the given context.
 *
 * @param context The context for which to get the contextual data
 * @param _entity The entity for which to get the contextual data. If not provided, the entity is assumed to be the current user. In this situation, if the user is a teacher
 *          all contextual data for the `context` will be returned.
 */
export function useContextualData(
	context:
		| {|
				type: 'MAP',
				map: Map | void | null,
				includeHiddenObjects?: boolean,
				includeUnscannedObjects?: boolean,
		  |}
		| {|
				type: 'CREATIVE_CANVAS',
				canvasActionId: string,
				maps: Array<Map>,
		  |},
	_entity?: ContextualDataEntity
): Array<StudentSpecificContextualDataEntry> {
	const studentId = useStudentId()
	const isTeacher = useMemo(() => isController(), [])
	const entity = _entity || (studentId && !isTeacher ? { studentId } : 'ANY')
	const { base, extra } = useSelector((state: ReduxStore) => {
		if (context.type === 'MAP') {
			const map = context.map
			if (!map) {
				return { base: [], extra: [] }
			}
			return getContextualData(state.jrPlusState, { ...context, map }, entity)
		}
		return getContextualData(state.jrPlusState, context, entity)
	}, isEqual)

	// Return dummy data in training
	if (
		context.type === 'CREATIVE_CANVAS' &&
		context.canvasActionId === TRAINING_CANVAS_PROPS.canvasId
	) {
		return TRAINING_CANVAS_DATA.data.map(data => ({
			id: data._id,
			title: data.name || 'Relevant Data',
			text: data.text,
			dataSource: { type: 'CREATIVE_CANVAS', canvasActionId: TRAINING_CANVAS_PROPS.canvasId },
			icon: null,
			media:
				data.type === 'TEXT'
					? []
					: // $FlowIgnore[speculation-ambiguous] - VIDEO, AUDIO, or IMAGE would all work right here
					  [{ type: data.type, url: data.url }],
			classVote: 0,
			studentVote: null,
			relevance: data.relevance,
		}))
	}

	return [...base, ...extra]
}
