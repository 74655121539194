// @flow
import {
	REDUX_WEBSOCKET_CONNECT,
	REDUX_WEBSOCKET_DISCONNECT,
	REDUX_WEBSOCKET_OPEN,
	REDUX_WEBSOCKET_CLOSED,
	REDUX_WEBSOCKET_SEND,
} from '../../utility/websocketConstants'

import { connect } from '@giantmachines/redux-websocket'

import createReducer, { type Reducer } from '../createReducer'
import config from '../../config'

import type { Coordinates, PointEvent } from '../../types'

import type { ReduxStore } from '../rootReducer'
import {
	isWantingToRunMissionAsControl,
	isWantingToRunMissionAsStudent,
} from '../../components/AppSetup'
import { getMissionServerUrl } from './general'

type SendMessageReturn = ReturnType<typeof sendMessage>
type WebSocketMessage = $PropertyType<SendMessageReturn, 'payload'>

export type SendMessageAction = {
	...SendMessageReturn,
	payload: WebSocketMessage & { meta?: { POINT_EVENT_ID?: string } },
}

export const STATUS_MESSAGE_RECONNECT_ATTEMPTS = 4
export const RECONNECT_DELAY = 500

export const SET_WEBSOCKET_URL = 'SET_WEBSOCKET_URL'

type ConnectWebSocketAction = {
	type: 'WEBSOCKET_CONNECT',
	payload: {
		url: string,
	},
}

type SetWebSocketUrlAction = {
	type: 'SET_WEBSOCKET_URL',
	payload: string,
}

type DisconnectWebSocketAction = {
	type: 'REDUX_WEBSOCKET::DISCONNECT',
}

type SendWebSocketMessageAction = {
	type: 'REDUX_WEBSOCKET::SEND',
	payload: { type: string, payload?: any },
	meta?: { POINT_EVENT_LOCATION: Coordinates | { x: string, y: string } },
}

/**
 * Gets the websocket url for the current mission server
 */
export function getMissionServerWebsocketAddress(missionServerUrl: string): string {
	const protocol = config.isDev ? 'ws' : 'wss'
	return `${protocol}://${missionServerUrl}/ws`
}

/**
 * Given a url without a protocol, gets the url with the correct protocol. In dev, uses `http`, otherwise `https`
 */
export function getMissionServerHttpAddress(missionServerUrl: string): string {
	const protocol = config.isDev ? 'http' : 'https'
	return `${protocol}://${missionServerUrl}`
}
/**
 * setWebSocketUrl - set the url for the websocket
 *
 * @param  {string} url - the websocket url to set
 * @param  {string} missionCode - the code of the mission
 * @param  {string} missionServerUrl - the http url of the mission server
 *
 * @returns SetWebSocketUrlAction
 */
export function setWebSocketUrl(
	url: string,
	missionCode: string,
	missionServerUrl: string
): SetWebSocketUrlAction {
	const joinAs: {
		isTeacher?: boolean,
		studentId?: string,
		connectionType?: string,
		missionCode: string,
	} = {
		missionCode,
	}
	if (isWantingToRunMissionAsControl()) {
		joinAs.isTeacher = true
		joinAs.connectionType = ['CONTROL', 'STUDENT'].join('&connectionType=')
	} else if (isWantingToRunMissionAsStudent()) {
		const studentId = sessionStorage.getItem(config.sessionStorageStudentIdKey)
		if (!studentId) {
			window.location.href = '/'
			// this error should never be thrown because we redirect to a new location above.
			throw new Error('Tried to connect to the mission as a student, but failed to redirect')
		}
		joinAs.studentId = studentId
	}

	localStorage.setItem(
		config.localStorageJoinPreviousMissionKey,
		JSON.stringify({ ...joinAs, missionServerUrl })
	)

	return {
		type: SET_WEBSOCKET_URL,
		payload: `${url}?${Object.keys(joinAs)
			.map(key => `${key}=${String(joinAs[key])}`)
			.join('&')}`,
	}
}

export function connectWebSocket(webSocketAddress: string): ConnectWebSocketAction {
	return connect(webSocketAddress)
}

export function disconnectWebSocket(): DisconnectWebSocketAction {
	return {
		type: REDUX_WEBSOCKET_DISCONNECT,
	}
}

export function sendMessage(
	type: string,
	payload?: any,
	pointEvent?: PointEvent
): SendWebSocketMessageAction {
	const action: SendWebSocketMessageAction = {
		type: REDUX_WEBSOCKET_SEND,
		payload: {
			type,
		},
	}
	if (payload != null) {
		action.payload.payload = payload
	}
	if (pointEvent) {
		action.meta = {
			POINT_EVENT_LOCATION: pointEvent.location,
		}
	}

	return action
}

export type WebSocketStore = {
	isOpen: boolean,
	isOpening: boolean,
	connectAttempts: number,
	webSocketUrl: string,
}

export function getInitialState(): WebSocketStore {
	return {
		isOpen: false,
		isOpening: false,
		connectAttempts: 0,
		webSocketUrl: '',
	}
}

export default (createReducer<WebSocketStore>(getInitialState(), {
	[REDUX_WEBSOCKET_CONNECT]: state => {
		return {
			...state,
			isOpening: true,
			connectAttempts: state.connectAttempts + 1,
		}
	},
	[REDUX_WEBSOCKET_OPEN]: state => {
		return {
			...state,
			isOpen: true,
			isOpening: false,
			connectAttempts: 0,
		}
	},
	[REDUX_WEBSOCKET_CLOSED]: state => {
		return {
			...state,
			isOpen: false,
			isOpening: false,
		}
	},
	[SET_WEBSOCKET_URL]: (state, action) => {
		return {
			...state,
			webSocketUrl: action.payload,
		}
	},
}): Reducer<WebSocketStore>)

// SELECTORS

export function getWebSocketUrl(state: ReduxStore): string {
	return state.webSocket.webSocketUrl
}

/**
 * Gets the base websocket url
 */
export function getBaseWebSocketUrl(state: ReduxStore): ?string {
	const missionServerUrl = getMissionServerUrl(state)
	if (!missionServerUrl) return null
	const stripProtocol = missionServerUrl.replace(/(^\w+:|^)\/\//, '')
	return getMissionServerWebsocketAddress(stripProtocol)
}

type RequestHandledCallback = (success: boolean) => void

export const requestHandledCallbackContainer: { [actionId: string]: RequestHandledCallback } = {}

/**
 * onRequestHandled - add a callback which will occur once a given request is handled by the server
 *
 * @param {string} requestId - the id of the request to add the callback for
 * @param {ActionHandledCallback} callback - a callback to call once the server responds saying that it handled the request
 *
 * @return {void}
 */
export function onRequestHandled(requestId: string, callback: RequestHandledCallback): void {
	requestHandledCallbackContainer[requestId] = callback
}

/**
 * removeOnRequestHandledHandler - remove the actionHandled handler tied to a requestId
 *
 * @param {string} requestId - the id of the request to remove handlers for
 *
 * @return {void}
 */
export function removeOnRequestHandledHandler(requestId: string): void {
	delete requestHandledCallbackContainer[requestId]
}
